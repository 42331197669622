import React, { useState, useEffect, useRef } from "react";
import { AlertCircle, Award, Clock, Star, Sparkles } from "lucide-react";

const words = ["HTML", "CSS", "REACT", "SERVER", "CACHE", "PYTHON"];
const grid = [
  ["H", "T", "M", "L", "A", "B", "C", "D", "E", "C"],
  ["Q", "S", "E", "R", "V", "E", "R", "G", "H", "A"],
  ["P", "Y", "T", "H", "O", "N", "J", "K", "L", "C"],
  ["X", "C", "S", "S", "O", "P", "Q", "R", "S", "H"],
  ["A", "B", "C", "D", "E", "F", "G", "H", "I", "E"],
  ["K", "L", "M", "T", "C", "A", "E", "R", "S", "T"],
];

// Custom confetti component
const Confetti = ({ active }) => {
  const canvasRef = useRef(null);
  const [particles, setParticles] = useState([]);
  const animationRef = useRef(null);

  // Create particles when activated
  useEffect(() => {
    if (active && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      // Create initial particles
      const newParticles = [];
      const colors = [
        "#FFD700",
        "#FF6347",
        "#7B68EE",
        "#3CB371",
        "#FF69B4",
        "#00BFFF",
      ];

      for (let i = 0; i < 150; i++) {
        newParticles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height - canvas.height,
          size: Math.random() * 8 + 2,
          color: colors[Math.floor(Math.random() * colors.length)],
          speedY: Math.random() * 3 + 1,
          speedX: (Math.random() - 0.5) * 2,
          rotation: Math.random() * 360,
          rotationSpeed: (Math.random() - 0.5) * 2,
        });
      }

      setParticles(newParticles);

      // Create more particles after a delay
      setTimeout(() => {
        const moreParticles = [...newParticles];
        for (let i = 0; i < 100; i++) {
          moreParticles.push({
            x: Math.random() * canvas.width * 0.3,
            y: Math.random() * canvas.height - canvas.height,
            size: Math.random() * 8 + 2,
            color: colors[Math.floor(Math.random() * colors.length)],
            speedY: Math.random() * 3 + 1,
            speedX: Math.random() * 2,
            rotation: Math.random() * 360,
            rotationSpeed: (Math.random() - 0.5) * 2,
          });
        }
        setParticles(moreParticles);
      }, 500);

      // Create more particles from the right side
      setTimeout(() => {
        const rightParticles = [...particles];
        for (let i = 0; i < 100; i++) {
          rightParticles.push({
            x: canvas.width - Math.random() * canvas.width * 0.3,
            y: Math.random() * canvas.height - canvas.height,
            size: Math.random() * 8 + 2,
            color: colors[Math.floor(Math.random() * colors.length)],
            speedY: Math.random() * 3 + 1,
            speedX: -Math.random() * 2,
            rotation: Math.random() * 360,
            rotationSpeed: (Math.random() - 0.5) * 2,
          });
        }
        setParticles(rightParticles);
      }, 1000);
    }
  }, [active]);

  // Animation loop
  useEffect(() => {
    if (!active || !canvasRef.current || particles.length === 0) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      let stillActive = false;
      const updatedParticles = particles.map((p) => {
        // Update position
        p.y += p.speedY;
        p.x += p.speedX;
        p.rotation += p.rotationSpeed;

        // Draw particle
        ctx.save();
        ctx.translate(p.x, p.y);
        ctx.rotate((p.rotation * Math.PI) / 180);
        ctx.fillStyle = p.color;
        ctx.fillRect(-p.size / 2, -p.size / 2, p.size, p.size);
        ctx.restore();

        // Check if particle is still in view
        if (p.y < canvas.height) {
          stillActive = true;
        }

        return p;
      });

      if (stillActive) {
        animationRef.current = requestAnimationFrame(animate);
      } else {
        // All particles out of view, stop animation
        setParticles([]);
      }
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [active, particles]);

  return (
    <canvas
      ref={canvasRef}
      className="fixed inset-0 pointer-events-none z-50"
      style={{ display: active ? "block" : "none" }}
    />
  );
};

const WordSearch = () => {
  const [selectedCells, setSelectedCells] = useState([]);
  const [foundWords, setFoundWords] = useState([]);
  const [points, setPoints] = useState(100); // Start with 100 points
  const [gameCompleted, setGameCompleted] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [showHint, setShowHint] = useState(false);
  const [currentHint, setCurrentHint] = useState({
    row: -1,
    col: -1,
    word: "",
  });
  const [hintsUsed, setHintsUsed] = useState(0);
  const [hintMessage, setHintMessage] = useState("");
  const [scoreAnimation, setScoreAnimation] = useState({
    show: false,
    value: 0,
  });
  const [lastFoundWord, setLastFoundWord] = useState("");
  const [showWordFoundAnimation, setShowWordFoundAnimation] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  // Initialize the game timer
  useEffect(() => {
    setStartTime(Date.now());
    const timer = setInterval(() => {
      setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime]);

  // Check if all words have been found
  useEffect(() => {
    if (foundWords.length === words.length && !gameCompleted) {
      setGameCompleted(true);
      setShowConfetti(true);
    }
  }, [foundWords, gameCompleted]);

  // Add document-wide mouse up event listener
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isSelecting) {
        const wordFound = checkSelectedWord();
        setIsSelecting(false);
        setSelectedCells([]);

        if (wordFound) {
          // Play a sound effect for found word (simplified to avoid errors)
          try {
            const audio = new Audio();
            audio.volume = 0.3;
            const oscillator = new (window.AudioContext ||
              window.webkitAudioContext)().createOscillator();
            oscillator.type = "sine";
            oscillator.frequency.setValueAtTime(440, 0);
            oscillator.connect(audio);
            oscillator.start();
            oscillator.stop(0.2);
          } catch (e) {
            console.log("Audio playback not supported");
          }
        }
      }
    };

    document.addEventListener("mouseup", handleGlobalMouseUp);
    document.addEventListener("touchend", handleGlobalMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleGlobalMouseUp);
      document.removeEventListener("touchend", handleGlobalMouseUp);
    };
  }, [isSelecting, selectedCells]);

  // Clear hint message after 3 seconds
  useEffect(() => {
    if (hintMessage) {
      const timer = setTimeout(() => {
        setHintMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [hintMessage]);

  // Handle score animation
  useEffect(() => {
    if (scoreAnimation.show) {
      const timer = setTimeout(() => {
        setScoreAnimation({ show: false, value: 0 });
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [scoreAnimation]);

  // Handle word found animation
  useEffect(() => {
    if (showWordFoundAnimation) {
      const timer = setTimeout(() => {
        setShowWordFoundAnimation(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showWordFoundAnimation]);

  // Calculate points based on word length
  const calculatePoints = (word) => {
    return word.length * 10;
  };

  const checkSelectedWord = () => {
    if (selectedCells.length < 2) return false;

    const selectedWord = selectedCells
      .map((cell) => grid[cell.row][cell.col])
      .join("");

    // Check if the current selection matches a word
    if (words.includes(selectedWord) && !foundWords.includes(selectedWord)) {
      const wordPoints = calculatePoints(selectedWord);
      setPoints((prevPoints) => prevPoints + wordPoints);
      setFoundWords((prevWords) => [...prevWords, selectedWord]);

      // Show point gain animation
      setScoreAnimation({ show: true, value: wordPoints });

      // Show word found animation
      setLastFoundWord(selectedWord);
      setShowWordFoundAnimation(true);

      return true;
    }

    return false;
  };

  const handleCellMouseDown = (rowIndex, colIndex) => {
    if (gameCompleted) return;

    setIsSelecting(true);
    setSelectedCells([{ row: rowIndex, col: colIndex }]);
  };

  const handleCellMouseEnter = (rowIndex, colIndex) => {
    if (!isSelecting || gameCompleted) return;

    // Prevent adding the same cell multiple times
    if (
      selectedCells.some(
        (cell) => cell.row === rowIndex && cell.col === colIndex
      )
    ) {
      return;
    }

    // Only allow selection in straight lines (horizontal, vertical, diagonal)
    if (selectedCells.length > 0) {
      const lastCell = selectedCells[selectedCells.length - 1];

      // Get direction of selection
      const rowDiff = rowIndex - lastCell.row;
      const colDiff = colIndex - lastCell.col;

      // Check if cell is adjacent
      const isAdjacent = Math.abs(rowDiff) <= 1 && Math.abs(colDiff) <= 1;
      if (!isAdjacent) return;

      // If we already have at least 2 cells, ensure we're continuing in the same direction
      if (selectedCells.length >= 2) {
        const prevCell = selectedCells[selectedCells.length - 2];
        const prevRowDiff = lastCell.row - prevCell.row;
        const prevColDiff = lastCell.col - prevCell.col;

        // Check if we're maintaining the same direction
        const isSameDirection =
          (rowDiff === 0 && prevRowDiff === 0) || // horizontal
          (colDiff === 0 && prevColDiff === 0) || // vertical
          (Math.abs(rowDiff) === Math.abs(colDiff) &&
            Math.abs(prevRowDiff) === Math.abs(prevColDiff) && // diagonal
            Math.sign(rowDiff) === Math.sign(prevRowDiff) &&
            Math.sign(colDiff) === Math.sign(prevColDiff));

        if (!isSameDirection) return;
      }
    }

    setSelectedCells((prev) => [...prev, { row: rowIndex, col: colIndex }]);
  };

  const handleTouchStart = (e, rowIndex, colIndex) => {
    e.preventDefault(); // Prevent default touch behavior
    handleCellMouseDown(rowIndex, colIndex);
  };

  const handleTouchMove = (e, rowIndex, colIndex) => {
    e.preventDefault(); // Prevent default touch behavior

    // Get the touch position
    const touch = e.touches[0];
    const element = document.elementFromPoint(touch.clientX, touch.clientY);

    // Check if we're over a cell
    if (
      element &&
      element.dataset &&
      element.dataset.row !== undefined &&
      element.dataset.col !== undefined
    ) {
      const touchRowIndex = parseInt(element.dataset.row);
      const touchColIndex = parseInt(element.dataset.col);
      handleCellMouseEnter(touchRowIndex, touchColIndex);
    }
  };

  const isSelected = (rowIndex, colIndex) =>
    selectedCells.some(
      (cell) => cell.row === rowIndex && cell.col === colIndex
    );

  const isHinted = (rowIndex, colIndex) =>
    showHint && currentHint.row === rowIndex && currentHint.col === colIndex;

  const isFoundInWord = (rowIndex, colIndex) => {
    // Check if this cell is part of a found word
    for (const word of foundWords) {
      const wordPositions = findWordPositions(word);
      if (
        wordPositions.some(
          (pos) => pos.row === rowIndex && pos.col === colIndex
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const findWordPositions = (word) => {
    const positions = [];

    // Check horizontal (left to right)
    for (let r = 0; r < grid.length; r++) {
      for (let c = 0; c <= grid[r].length - word.length; c++) {
        const horizontal = grid[r].slice(c, c + word.length).join("");
        if (horizontal === word) {
          for (let i = 0; i < word.length; i++) {
            positions.push({ row: r, col: c + i });
          }
          return positions;
        }
      }
    }

    // Check vertical (top to bottom)
    for (let c = 0; c < grid[0].length; c++) {
      for (let r = 0; r <= grid.length - word.length; r++) {
        let vertical = "";
        for (let i = 0; i < word.length; i++) {
          vertical += grid[r + i][c];
        }
        if (vertical === word) {
          for (let i = 0; i < word.length; i++) {
            positions.push({ row: r + i, col: c });
          }
          return positions;
        }
      }
    }

    // Check diagonal (top-left to bottom-right)
    for (let r = 0; r <= grid.length - word.length; r++) {
      for (let c = 0; c <= grid[r].length - word.length; c++) {
        let diagonal = "";
        for (let i = 0; i < word.length; i++) {
          diagonal += grid[r + i][c + i];
        }
        if (diagonal === word) {
          for (let i = 0; i < word.length; i++) {
            positions.push({ row: r + i, col: c + i });
          }
          return positions;
        }
      }
    }

    return positions;
  };

  const useHint = () => {
    // Determine which words are still unfound
    const remainingWords = words.filter((word) => !foundWords.includes(word));

    if (remainingWords.length === 0) return;

    // Check if enough points are available
    const hintCost = 25;
    if (points < hintCost) {
      setHintMessage(`Not enough points for hint! (Costs ${hintCost} points)`);
      return;
    }

    // Get a random unfound word
    const randomWord =
      remainingWords[Math.floor(Math.random() * remainingWords.length)];

    // Find the starting position of the word
    const positions = findWordPositions(randomWord);
    if (positions.length > 0) {
      // Show hint
      setCurrentHint({
        row: positions[0].row,
        col: positions[0].col,
        word: randomWord,
      });
      setShowHint(true);

      // Deduct points
      setPoints(points - hintCost);
      setHintsUsed(hintsUsed + 1);

      // Set message
      setHintMessage(
        `Hint used! First letter of "${randomWord}" revealed. (-${hintCost} points)`
      );

      // Hide hint after 3 seconds
      setTimeout(() => {
        setShowHint(false);
      }, 3000);
    }
  };

  const resetGame = () => {
    setFoundWords([]);
    setPoints(100);
    setGameCompleted(false);
    setStartTime(Date.now());
    setElapsedTime(0);
    setHintsUsed(0);
    setShowConfetti(false);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  // Animated stars component for the congratulations modal
  const AnimatedStars = () => {
    return (
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {[...Array(20)].map((_, i) => {
          const size = Math.random() * 10 + 5;
          const top = Math.random() * 100;
          const left = Math.random() * 100;
          const animationDelay = Math.random() * 2;
          const animationDuration = Math.random() * 3 + 2;

          return (
            <div
              key={i}
              className="absolute text-yellow-300"
              style={{
                top: `${top}%`,
                left: `${left}%`,
                fontSize: `${size}px`,
                animation: `twinkle ${animationDuration}s infinite ${animationDelay}s`,
              }}
            >
              ★
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="p-6 bg-gradient-to-b from-black to-darkBlue min-h-screen text-center">
      {/* Confetti */}
      <Confetti active={showConfetti} />

      <h1 className="text-4xl pt-32 font-bold mb-4 text-white drop-shadow-lg">
        <span className="animate-pulse mb-6">Tech Wizzard</span>
      </h1>

      {/* Game stats */}
      <div className="flex justify-center flex-wrap gap-4 mb-4">
        <div className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
          <div className="flex items-center gap-2">
            <Star size={18} />
            <span className="font-bold">Points:</span>
            <span className="text-xl">{points}</span>
            {scoreAnimation.show && (
              <span className="absolute ml-24 text-yellow-300 font-bold animate-bounce">
                +{scoreAnimation.value}
              </span>
            )}
          </div>
        </div>
        <div className="bg-purple-600 text-white px-4 py-2 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
          <div className="flex items-center gap-2">
            <Clock size={18} />
            <span className="font-bold">Time:</span>
            <span className="text-xl">{formatTime(elapsedTime)}</span>
          </div>
        </div>
        <div className="bg-green-600 text-white px-4 py-2 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
          <div className="flex items-center gap-2">
            <Award size={18} />
            <span className="font-bold">Found:</span>
            <span className="text-xl">
              {foundWords.length}/{words.length}
            </span>
          </div>
        </div>
        <div className="bg-amber-600 text-white px-4 py-2 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
          <div className="flex items-center gap-2">
            <AlertCircle size={18} />
            <span className="font-bold">Hints:</span>
            <span className="text-xl">{hintsUsed}</span>
          </div>
        </div>
      </div>

      {/* Word found animation */}
      {showWordFoundAnimation && (
        <div className="fixed top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          <div className="text-3xl font-bold text-yellow-300 drop-shadow-lg animate-ping">
            {lastFoundWord} Found!
          </div>
        </div>
      )}

      {/* Hint button */}
      <button
        onClick={useHint}
        disabled={gameCompleted}
        className="mb-4 bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white font-semibold py-2 px-4 rounded-lg flex items-center mx-auto gap-2 disabled:opacity-50 disabled:cursor-not-allowed shadow-lg transform hover:scale-105 transition-all"
      >
        <AlertCircle size={16} />
        Use Hint (25 points)
      </button>

      {/* Hint message */}
      {hintMessage && (
        <div className="mb-4 text-yellow-700 bg-yellow-100 p-2 rounded-md inline-block animate-pulse">
          {hintMessage}
        </div>
      )}

      {/* Game board */}
      <div className="grid grid-cols-10 gap-1 bg-white bg-opacity-10 backdrop-blur-sm shadow-xl p-4 rounded-lg max-w-md mx-auto border border-purple-300">
        {grid.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {row.map((letter, colIndex) => {
              const isFound = isFoundInWord(rowIndex, colIndex);
              const isCurrentlySelected = isSelected(rowIndex, colIndex);
              const isCurrentlyHinted = isHinted(rowIndex, colIndex);

              return (
                <div
                  key={colIndex}
                  data-row={rowIndex}
                  data-col={colIndex}
                  className={`w-10 h-10 flex items-center justify-center border-2 text-lg font-bold cursor-pointer select-none rounded-md shadow-md transition-all duration-300
                                        ${
                                          isCurrentlySelected
                                            ? "bg-blue-400 text-white scale-110 border-blue-600"
                                            : isCurrentlyHinted
                                            ? "bg-yellow-300 border-yellow-600 animate-pulse"
                                            : isFound
                                            ? "bg-green-400 text-white border-green-600"
                                            : "bg-white border-gray-300 hover:bg-gray-100"
                                        }`}
                  onMouseDown={() => handleCellMouseDown(rowIndex, colIndex)}
                  onMouseEnter={() => handleCellMouseEnter(rowIndex, colIndex)}
                  onTouchStart={(e) => handleTouchStart(e, rowIndex, colIndex)}
                  onTouchMove={(e) => handleTouchMove(e, rowIndex, colIndex)}
                  style={{
                    transform: isCurrentlySelected ? "scale(1.1)" : "scale(1)",
                    animation:
                      isFound && !isCurrentlySelected ? "fadeIn 0.5s" : "none",
                  }}
                >
                  {letter}
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>

      {/* Word list */}
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-2 text-white">
          Find These Words:
        </h2>
        <div className="grid grid-cols-3 gap-2 max-w-md mx-auto">
          {words.map((word) => {
            const isFound = foundWords.includes(word);
            const isHinted = currentHint.word === word && showHint;

            return (
              <div
                key={word}
                className={`p-2 rounded-md font-medium transition-all duration-500 shadow-md
                                    ${
                                      isFound
                                        ? "bg-green-500 text-white scale-105"
                                        : isHinted
                                        ? "bg-yellow-300 animate-pulse"
                                        : "bg-gray-200"
                                    }`}
                style={{
                  animation: isFound ? "wordComplete 1s" : "none",
                }}
              >
                {word}{" "}
                {isFound && (
                  <span className="text-yellow-200 text-sm">
                    (+{calculatePoints(word)})
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* Game instructions */}
      <div className="mt-6 text-white max-w-md mx-auto text-left bg-black bg-opacity-30 p-4 rounded-lg">
        <h3 className="font-semibold">How to Play:</h3>
        <ul className="list-disc pl-5 text-sm">
          <li>
            Click and drag to select letters in a straight line (horizontal,
            vertical, or diagonal)
          </li>
          <li>Each found word earns 10 points per letter</li>
          <li>
            Use the hint button to reveal the first letter of a word (costs 25
            points)
          </li>
          <li>Find all words to complete the game</li>
        </ul>
      </div>

      {/* CSS Animations */}

      {/* Congratulations modal */}
      {gameCompleted && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-40">
          <div
            className="bg-gradient-to-r from-purple-500 to-blue-500 p-8 rounded-lg shadow-2xl max-w-md w-full mx-4 text-center relative"
            style={{ animation: "fadeInUp 0.8s forwards" }}
          >
            <AnimatedStars />

            <div className="relative">
              <div
                className="absolute -top-12 -left-12 text-yellow-300 animate-spin"
                style={{ animationDuration: "8s" }}
              >
                <Sparkles size={48} />
              </div>
              <div
                className="absolute -top-12 -right-12 text-yellow-300 animate-spin"
                style={{
                  animationDuration: "8s",
                  animationDirection: "reverse",
                }}
              >
                <Sparkles size={48} />
              </div>

              <h2
                className="text-4xl font-bold text-white mb-4"
                style={{ animation: "pulse 2s infinite" }}
              >
                Congratulations!
              </h2>

              <div className="mb-6 bg-white bg-opacity-20 p-4 rounded-lg backdrop-blur-sm">
                <p className="text-xl mb-4 text-white">
                  You found all the words!
                </p>
                <div className="text-white text-lg font-semibold">
                  Total Time: {formatTime(elapsedTime)}
                  <br />
                  Final Score: {points}
                  <br />
                  Hints Used: {hintsUsed}
                </div>
              </div>

              <button
                onClick={resetGame}
                className="mt-6 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-md transform hover:scale-105 transition-all"
              >
                Play Again
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WordSearch;
