import { useThree, useFrame } from '@react-three/fiber';
import { useRef, useEffect } from 'react';
import * as THREE from 'three';

const GradientBackground = ({ 
  topColor = '#317FA7', 
  bottomColor = '#FFFFFF',
  speed = 0.001 
}) => {
  const { scene } = useThree();
  const materialRef = useRef();
  const timeRef = useRef(0);

  useEffect(() => {
    scene.background = null;
    
    // Cleanup function
    return () => {
      scene.background = new THREE.Color('#000000');
    };
  }, [scene]);

  useFrame((state, delta) => {
    if (materialRef.current) {
      timeRef.current += delta * speed;
      materialRef.current.uniforms.uTime.value = timeRef.current;
    }
  });

  // Convert hex colors to THREE.Vector3
  const topColorVector = new THREE.Color(topColor).toArray();
  const bottomColorVector = new THREE.Color(bottomColor).toArray();

  return (
    <mesh position={[0, 0, -50]}>
      <planeGeometry args={[200, 200]} />
      <shaderMaterial
        ref={materialRef}
        uniforms={{
          uTopColor: { value: topColorVector },
          uBottomColor: { value: bottomColorVector },
          uTime: { value: 0 },
        }}
        vertexShader={`
          varying vec2 vUv;
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `}
        fragmentShader={`
          uniform vec3 uTopColor;
          uniform vec3 uBottomColor;
          uniform float uTime;
          varying vec2 vUv;

          // Simple noise function
          float noise(vec2 st) {
            return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
          }

          void main() {
            // Create a subtle wavy effect
            float wave = sin(vUv.y * 10.0 + uTime) * 0.02;
            
            // Add some noise for texture
            float noiseValue = noise(vUv + uTime * 0.1) * 0.03;
            
            // Calculate gradient with subtle effects
            float mixFactor = vUv.y + wave + noiseValue;
            mixFactor = clamp(mixFactor, 0.0, 1.0);
            
            // Mix the colors
            vec3 color = mix(uBottomColor, uTopColor, mixFactor);
            
            // Add a slight vignette effect
            float vignetteStrength = 0.15;
            vec2 center = vec2(0.5) - vUv;
            float vignette = 1.0 - dot(center, center) * vignetteStrength;
            
            gl_FragColor = vec4(color * vignette, 1.0);
          }
        `}
      />
    </mesh>
  );
};

export default GradientBackground;