import React, { useState, useEffect } from "react";
import { Menu, X, Globe } from "lucide-react";
import { Link } from "react-router-dom"; // Import Link
import ScrollToTop from "./ScrollToTop";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState("/"); // Track active link

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    // Set initial active link based on current path
    setActiveLink(window.location.pathname);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    // Prevent scrolling when menu is open
    document.body.style.overflow = isOpen ? "auto" : "hidden";
  };

  const navLinks = [
    { name: "Home", path: "/" },
    { name: "Industries", path: "/industries" },
    { name: "About Us", path: "/about" },
    { name: "Careers", path: "/careers" },
    { name: "Contact Sales", path: "/contact" },
    
  ];

  return (
    <nav
      className={`fixed w-full top-0 z-50 transition-all duration-300 ${
        scrolled
          ? "bg-white shadow-lg py-2"
          : "bg-transparent text-white py-4"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          {/* Logo section */}
          <div className="flex-shrink-0 flex items-center">
            <Link
              to="/"
              className={`flex items-center text-lg font-medium transition-all duration-200 group ${
                activeLink === "/"
                  ? "text-middleBlue"
                  : "text-gray-700 hover:text-middleBlue"
              }`}
              onClick={() => {
                setActiveLink("/");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <div className={`rounded-full transition-all duration-300`}></div>
              <img
                className={`transition-all duration-300 bg-white/80 rounded-full ${
                  scrolled ? "h-14 w-36" : "h-16 w-40"
                }`}
                src="https://ik.imagekit.io/jncw2kb8u/image%20(1).png?updatedAt=1742588376201"
                alt="Dodun Soft Solution Logo"
              />
            </Link>
          </div>

          {/* Desktop navigation */}
          <div className="hidden md:flex items-center">
            <div className="rounded-full p-1 mr-6">
              {navLinks.map((link) => (
                <Link
                  key={link.name}
                  to={link.path}
                  className={`px-4 py-2 text-sm font-medium transition-all duration-200 rounded-full inline-block ${
                    activeLink === link.path
                      ? "bg-middleBlue text-white shadow-md"
                      : scrolled
                      ? "text-gray-800 hover:bg-gray-300"
                      : "text-white hover:bg-gray-700"
                  }`}
                  onClick={() => setActiveLink(link.path)}
                >
                  {link.icon && <span className="mr-2">{link.icon}</span>}
                  {link.name}
                </Link>
              ))}
            </div>
          </div>

          {/* Mobile menu button with improved styling */}
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className={`flex items-center justify-center h-10 w-10 rounded-full transition-all duration-300 ${
                isOpen
                  ? "bg-blue-100 text-middleBlue rotate-90"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              }`}
              aria-expanded={isOpen}
            >
              <span className="sr-only">Toggle menu</span>
              {isOpen ? (
                <X className="h-5 w-5" aria-hidden="true" />
              ) : (
                <Menu className="h-5 w-5" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu with card-style design */}
      <div
        className={`fixed inset-y-0 right-0 max-w-sm w-full bg-white shadow-2xl transform transition-all duration-500 ease-in-out z-40 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } overflow-y-auto`}
      >
        <div className="h-full flex flex-col">
          {/* Menu header */}
          <div className="flex items-center justify-between h-20 px-6 border-b">
            <div className="flex items-center">
              <Globe className="h-5 w-5 text-middleBlue mr-2" />
            </div>
            <button
              onClick={toggleMenu}
              className="flex items-center justify-center h-10 w-10 rounded-full bg-gray-100 text-gray-700 hover:bg-gray-200 transition-all duration-200"
            >
              <X className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>

          {/* Menu content */}
          <div className="flex-grow px-6 py-8">
            <div className="space-y-6">
              {navLinks.map((link) => (
                <Link
                  key={link.name}
                  to={link.path}
                  className={`flex items-center text-lg font-medium transition-all duration-200 group ${
                    activeLink === link.path
                      ? "text-middleBlue"
                      : "text-gray-700 hover:text-middleBlue"
                  }`}
                  onClick={() => {
                    setActiveLink(link.path);
                    toggleMenu();
                  }}
                >
                  <div
                    className={`mr-3 h-10 w-1 rounded-full transition-all duration-300 ${
                      activeLink === link.path
                        ? "bg-middleBlue"
                        : "bg-transparent group-hover:bg-blue-200"
                    }`}
                  ></div>
                  {link.icon && <span className="mr-2">{link.icon}</span>}
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Improved overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black/40 backdrop-blur-sm md:hidden z-30 transition-opacity duration-500"
          onClick={toggleMenu}
        ></div>
      )}
    </nav>
  );
};

export default Navbar;
