import './App.css';
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router
import Layout from './Layout';

function App() {
  return (
    <div className="App">
      <Router> {/* Wrap Layout with Router */}
        <Layout />
      </Router>
    </div>
  );
}

export default App;
