import React from 'react';
import { Mail, Phone, MapPin, Facebook, Twitter, Instagram, Linkedin, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white md:pt-16 pb-8 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info Section */}
          <div className="flex flex-col space-y-4">
            <img
              src="https://ik.imagekit.io/jncw2kb8u/image%20(1).png?updatedAt=1742588376201"
              alt="Dodun Soft Solution Logo"
              className="h-36 w-96   "
            />
            <p className="text-gray-400 text-sm leading-relaxed">
              Dodun Soft Solution provides innovative software solutions for businesses
              of all sizes. We specialize in web development, mobile apps, and custom
              software solutions that drive growth and efficiency.
            </p>
            <div className="flex space-x-4 mt-4">
              <div className="flex space-x-4 mt-4">
                <a href="https://wa.me/8968881110" className="bg-green-500 hover:bg-green-600 p-2 rounded-full transition-colors duration-200" target="_blank" rel="noopener noreferrer">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png" alt="WhatsApp" width={18} height={18} />
                </a>
                <a href="https://www.instagram.com/dodun.softsolutions" className="bg-pink-600 hover:bg-pink-700 p-2 rounded-full transition-colors duration-200" target="_blank" rel="noopener noreferrer">
                  <Instagram size={18} />
                </a>
                <a href="https://www.linkedin.com/company/dodun-soft-solutions-mohali" className="bg-blue-700 hover:bg-blue-800 p-2 rounded-full transition-colors duration-200" target="_blank" rel="noopener noreferrer">
                  <Linkedin size={18} />
                </a>
              </div>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold mb-6 relative">
              Quick Links
              <span className="absolute bottom-0 left-0 w-12 h-1 bg-darkBlue -mb-2"></span>
            </h3>
            <ul className="space-y-3">
              {[
                { label: 'Home', path: '/' },
                { label: 'Industry', path: '/industries' },
                { label: 'About Us', path: '/about' },
                { label: 'Career', path: '/careers' },
                { label: 'Contact Us', path: '/contact' }
              ].map(({ label, path }) => (
                <li key={label}>
                  <Link to={path} className="text-gray-400 hover:text-white transition-colors duration-200 flex items-center">
                    <ArrowRight size={14} className="mr-2 text-darkBlue" />
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-lg font-semibold mb-6 relative">
              Contact Us
              <span className="absolute bottom-0 left-0 w-12 h-1 bg-darkBlue -mb-2"></span>
            </h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <MapPin className="mr-3 text-darkBlue flex-shrink-0 mt-1" size={18} />
                <span className="text-gray-400">
                  GM Plaza, Phase -7 <br />Industrial Area,
                  Sector-73,<br /> Mohali, India
                </span>
              </li>
              <li className="flex items-center">
                <Phone className="mr-3 text-darkBlue flex-shrink-0" size={18} />
                <a href="tel:+918968881110" className="text-gray-400">+91-8968881110</a>
              </li>
              <li className="flex items-center">
                <Mail className="mr-3 text-darkBlue flex-shrink-0" size={18} />
                <a href="mailto:support@dodunsoftsolutions.com" className="text-gray-400">support@dodunsoftsolutions.com</a>
              </li>

            </ul>
          </div>

          {/* Map Location */}
          <div>
            <h3 className="text-lg font-semibold mb-6 relative">
              Find Us
              <span className="absolute bottom-0 left-0 w-12 h-1 bg-darkBlue -mb-2"></span>
            </h3>
            <div className="flex flex-col items-center sm:items-start">
              <h3 className="text-xl font-semibold mb-4">Our Location</h3>
              <div className="w-full h-48 rounded-lg overflow-hidden">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.145327051417!2d76.6961861841247!3d30.71431462102898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fefdcb3a9035b%3A0x46edab8a4ca6d8a4!2sDODUN%20SOFT%20SOLUTIONS%20(OPC)%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sus!4v1742657856651!5m2!1sen!2sus"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex="0"
                  title="Dodun Soft Solutions Location"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-500 text-sm">
          <p>© {new Date().getFullYear()} Dodun Soft Solution. All rights reserved.</p>
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;