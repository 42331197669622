import React, { useState, useEffect } from "react";
import {
  UtensilsIcon,
  HeartIcon,
  ShoppingCartIcon,
  GraduationCapIcon,
  BuildingIcon,
  BriefcaseIcon,
  ArrowRightIcon,
  CheckCircleIcon,
} from "lucide-react";
import HeroSection from "../components/HeroSection";
import FAQPage from "../components/FAQs";
import { motion, AnimatePresence } from "framer-motion";

const industries = [
  {
    name: "Education",
    icon: <GraduationCapIcon className="w-6 h-6 text-white" />,
    software: [
      "Online course platforms with video modules",
      "Student attendance & test management",
      "Teacher dashboards & live class features",
      "Gamification features for student engagement", // Added software point
    ],
    marketing: [
      "YouTube & Instagram education reels",
      "Webinar marketing & registration funnels",
      "Parents & student-focused social campaigns",
      "Collaborations with educational influencers", // Added marketing point
    ],
    color: "from-blue-400 to-blue-500",
    lightColor: "bg-blue-50",
    accentColor: "text-blue-600",
    highlightColor: "bg-blue-500",
  },
  {
    name: "Hospitality",
    icon: <UtensilsIcon className="w-6 h-6 text-white" />,
    software: [
      "Custom POS systems for cafes and restaurants",
      "Order & table management apps",
      "Inventory and billing automation",
      "Customer loyalty programs and reward systems", // Added software point
    ],
    marketing: [
      "Reel-based promotions on Instagram & Facebook",
      "Menu graphics, offers & event marketing",
      "Influencer & local SEO campaigns",
      "Seasonal promotions & holiday-themed campaigns", // Added marketing point
    ],
    color: "from-yellow-400 to-yellow-500",
    lightColor: "bg-yellow-50",
    accentColor: "text-yellow-600",
    highlightColor: "bg-yellow-500",
  },
  {
    name: "Healthcare",
    icon: <HeartIcon className="w-6 h-6 text-white" />,
    software: [
      "Online appointment booking portals",
      "Patient record & treatment tracking",
      "Medicine inventory & billing tools",
      "Telemedicine integration for remote consultations", // Added software point
    ],
    marketing: [
      "Clinic awareness via regional targeting",
      "Patient testimonial videos & post designs",
      "Health tip series to grow social following",
      "Online health quizzes & interactive campaigns", // Added marketing point
    ],
    color: "from-red-400 to-red-500",
    lightColor: "bg-red-50",
    accentColor: "text-red-600",
    highlightColor: "bg-red-500",
  },
  {
    name: "Retail",
    icon: <ShoppingCartIcon className="w-6 h-6 text-white" />,
    software: [
      "E-commerce website & mobile apps",
      "Stock, order, and delivery management",
      "Payment integration and invoice systems",
      "Customer feedback & review management systems", // Added software point
    ],
    marketing: [
      "Product shoots & catalog design",
      "Social media ads for specific products",
      "Email & WhatsApp marketing setup",
      "Influencer partnerships for product promotions", // Added marketing point
    ],
    color: "from-green-400 to-green-500",
    lightColor: "bg-green-50",
    accentColor: "text-green-600",
    highlightColor: "bg-green-500",
  },
  {
    name: "Real Estate",
    icon: <BuildingIcon className="w-6 h-6 text-white" />,
    software: [
      "Property listing & inquiry portals",
      "Broker management & CRM systems",
      "Virtual property tours with 360° views",
      "Property valuation & market analysis tools", // Added software point
    ],
    marketing: [
      "Facebook marketplace property ads",
      "Lead capture landing pages",
      "Instagram carousel property showcases",
      "Interactive virtual tour promotions on social media", // Added marketing point
    ],
    color: "from-purple-400 to-purple-500",
    lightColor: "bg-purple-50",
    accentColor: "text-purple-600",
    highlightColor: "bg-purple-500",
  },
  {
    name: "Finance",
    icon: <BriefcaseIcon className="w-6 h-6 text-white" />,
    software: [
      "Loan processing & EMI tools",
      "Financial dashboards for customers",
      "Secure KYC & document handling systems",
      "Investment tracking & portfolio management tools", // Added software point
    ],
    marketing: [
      "Trust-building educational posts",
      "SEO blogs on finance topics",
      "Social campaigns for app downloads",
      "Online financial calculators for user engagement", // Added marketing point
    ],
    color: "from-teal-400 to-teal-500",
    lightColor: "bg-teal-50",
    accentColor: "text-teal-600",
    highlightColor: "bg-teal-500",
  },
];

const IndustryPage = () => {
  const [activeIndustry, setActiveIndustry] = useState(industries[0]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(false);
    setTimeout(() => setIsVisible(true), 100);
  }, [activeIndustry]);

  return (
    <div className="min-h-screen bg-gray-50">
      <HeroSection />

      <div className="py-12 px-4 max-w-7xl mx-auto">
        <div className="relative mb-20 text-center">
          <h1 className="text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-gray-700 to-gray-900">
            Industry Solutions
          </h1>
        </div>

        <div className="flex overflow-x-auto pb-6 scrollbar-hide mb-12 space-x-4 md:justify-center">
          {industries.map((industry) => (
            <button
              key={industry.name}
              onClick={() => setActiveIndustry(industry)}
              className={`flex-shrink-0 py-3 px-6 rounded-full font-medium transition-all duration-300 ${
                activeIndustry.name === industry.name
                  ? `${industry.lightColor} text-white shadow-lg`
                  : "bg-white hover:bg-gray-100 text-gray-800"
              }`}
            >
              <div className="flex items-center space-x-2">
                <div
                  className={`p-1 rounded-full ${
                    activeIndustry.name === industry.name
                      ? `${industry.highlightColor}`
                      : `bg-gradient-to-r ${industry.color}`
                  }`}
                >
                  {industry.icon}
                </div>
                <span className={`text-black`}>{industry.name}</span>
              </div>
            </button>
          ))}
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeIndustry.name}
            initial={{ opacity: 0, scale: 0.8, x: 50 }}
            animate={{ opacity: 1, scale: 1, x: 0 }}
            exit={{ opacity: 0, scale: 0.8, x: -50 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="grid grid-cols-1 md:grid-cols-3 gap-2"
          >
            {/* Retail Industry Overview */}
            <div
              className={`p-1 rounded-2xl bg-gradient-to-r ${activeIndustry.color}`}
            >
              <div className="bg-white rounded-xl p-8">
                <div className="flex items-center mb-4">
                <div
                  className={`p-1 bg-black mr-2 rounded-full`}
                >
                  {activeIndustry.icon}
                </div>
                  <h2 className="text-3xl font-bold text-gray-800">
                    {activeIndustry.name}
                  </h2>
                </div>
                <p className="text-gray-600 mb-8">
                  Our comprehensive solutions for the{" "}
                  {activeIndustry.name.toLowerCase()} industry are designed to
                  boost efficiency, improve customer experiences, and drive
                  growth through cutting-edge technology and strategic
                  marketing.
                </p>
                <div
                  className={`${activeIndustry.lightColor} my-6 p-4 rounded-lg`}
                >
                  <div className="flex items-center mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`h-5 w-5 ${activeIndustry.accentColor} mr-2`}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                    </svg>
                    <h3
                      className={`font-semibold ${activeIndustry.accentColor}`}
                    >
                      Marketing
                    </h3>
                  </div>
                  <p className="text-sm text-gray-600">
                    Targeted strategies to reach your ideal audience.
                  </p>
                </div>
                <div className="space-y-4">
                  <div className="flex items-center">
                    <div
                      className={`${activeIndustry.highlightColor} p-1 rounded-full mr-3`}
                    >
                      <CheckCircleIcon className="w-5 h-5 text-white" />
                    </div>
                    <span className="text-gray-700">
                      Tailored to industry requirements
                    </span>
                  </div>
                  <div className="flex items-center">
                    <div
                      className={`${activeIndustry.highlightColor} p-1 rounded-full mr-3`}
                    >
                      <CheckCircleIcon className="w-5 h-5 text-white" />
                    </div>
                    <span className="text-gray-700">
                      Integration with existing systems
                    </span>
                  </div>
                  <div className="flex items-center">
                    <div
                      className={`${activeIndustry.highlightColor} p-1 rounded-full mr-3`}
                    >
                      <CheckCircleIcon className="w-5 h-5 text-white" />
                    </div>
                    <span className="text-gray-700">
                      Ongoing support and updates
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Software Solutions */}

            <div className="bg-white rounded-2xl shadow-md overflow-hidden">
              <div
                className={`h-2 bg-gradient-to-r ${activeIndustry.color}`}
              ></div>
              <div className="p-6">
                <div className="flex items-center mb-6">
                  <div
                    className={`p-2 rounded-lg ${activeIndustry.lightColor} mr-3`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`w-6 h-6 ${activeIndustry.accentColor}`}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <rect
                        x="2"
                        y="3"
                        width="20"
                        height="14"
                        rx="2"
                        ry="2"
                      ></rect>
                      <line x1="8" y1="21" x2="16" y2="21"></line>
                      <line x1="12" y1="17" x2="12" y2="21"></line>
                    </svg>
                  </div>
                  <h3 className="text-xl font-bold text-gray-800">
                    Software Solutions
                  </h3>
                </div>

                <ul className="space-y-4 mt-4">
                  {activeIndustry.software.map((item, index) => (
                    <li
                      key={index}
                      className="flex p-3 rounded-lg transition-colors hover:bg-gray-50"
                    >
                      <div className="flex-shrink-0 mt-1">
                        <div
                          className={`${activeIndustry.lightColor} ${activeIndustry.accentColor} rounded-full p-1 mr-3`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                        </div>
                      </div>
                      <div>
                        <p className="text-gray-700">{item}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Marketing Solutions */}
            <div className="bg-white rounded-2xl shadow-md overflow-hidden">
              <div
                className={`h-2 bg-gradient-to-r ${activeIndustry.color}`}
              ></div>
              <div className="p-6">
                <div className="flex items-center mb-6">
                  <div
                    className={`p-2 rounded-lg ${activeIndustry.lightColor} mr-3`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`w-6 h-6 ${activeIndustry.accentColor}`}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                    </svg>
                  </div>
                  <h3 className="text-xl font-bold text-gray-800">
                    Marketing Solutions
                  </h3>
                </div>

                <ul className="space-y-4 mt-4">
                  {activeIndustry.marketing.map((item, index) => (
                    <li
                      key={index}
                      className="flex p-3 rounded-lg transition-colors hover:bg-gray-50"
                    >
                      <div className="flex-shrink-0 mt-1">
                        <div
                          className={`${activeIndustry.lightColor} ${activeIndustry.accentColor} rounded-full p-1 mr-3`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                        </div>
                      </div>
                      <div>
                        <p className="text-gray-700">{item}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      <FAQPage />
    </div>
  );
};

export default IndustryPage;
