import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Send } from 'lucide-react';

const ApplyJob = () => {
  const { jobId } = useParams();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    resumeLink: '',
    coverLetter: '',
    digitalSignature: '',
    educationDetails: [{ degree: '', institution: '', year: '' }],
    experience: [{ company: '', position: '', years: '', description: '' }],
    projectLinks: [''],
    address: { street: '', city: '', state: '', pincode: '' },
    canRelocate: false,
    consent: false,
    jobId: jobId || ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;

    if (name in formData.address) {
      setFormData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          [name]: val
        }
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: val }));
    }
  };

  const handleEducationChange = (index, e) => {
    const updated = [...formData.educationDetails];
    updated[index][e.target.name] = e.target.value;
    setFormData({ ...formData, educationDetails: updated });
  };

  const handleExperienceChange = (index, e) => {
    const updated = [...formData.experience];
    updated[index][e.target.name] = e.target.value;
    setFormData({ ...formData, experience: updated });
  };

  const handleProjectLinkChange = (index, e) => {
    const updated = [...formData.projectLinks];
    updated[index] = e.target.value;
    setFormData({ ...formData, projectLinks: updated });
  };

  const addEducation = () =>
    setFormData(prev => ({
      ...prev,
      educationDetails: [...prev.educationDetails, { degree: '', institution: '', year: '' }]
    }));

  const addExperience = () =>
    setFormData(prev => ({
      ...prev,
      experience: [...prev.experience, { company: '', position: '', years: '', description: '' }]
    }));

  const addProjectLink = () =>
    setFormData(prev => ({ ...prev, projectLinks: [...prev.projectLinks, ''] }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.consent) return alert("Please consent to data usage.");

    try {
      const res = await fetch('https://api.dodunsoftsolutions.com/api/applicants/apply', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, job: jobId }),
      });

      if (res.ok) {
        alert("Application submitted successfully!");
        window.location.reload();
      } else {
        alert("Submission failed. Try again.");
      }
    } catch (err) {
      console.error(err);
      alert("Server error.");
    }
  };

  return (
<section className="py-8 md:py-16 bg-gray-50">
  <div className="max-w-4xl mx-auto p-4 md:p-8 bg-white shadow-lg rounded-lg border border-gray-100">
    <h2 className="text-2xl md:text-3xl font-bold mb-6 md:mb-8 text-center text-gray-800">Application Form</h2>
    
    <form onSubmit={handleSubmit} className="space-y-6 md:space-y-8">
      {/* Personal Information Section */}
      <div className="bg-gray-50 p-4 md:p-6 rounded-lg border border-gray-200">
        <h3 className="text-lg md:text-xl font-semibold mb-4 text-gray-700 flex items-center">
          <span className="bg-blue-600 text-white rounded-full w-6 h-6 inline-flex items-center justify-center mr-2 text-sm">1</span>
          Personal Information
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4">
          <input 
            name="name" 
            type="text" 
            required 
            placeholder="Full Name" 
            value={formData.name} 
            onChange={handleChange} 
            className="px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all w-full" 
          />
          <input 
            name="email" 
            type="email" 
            required 
            placeholder="Email Address" 
            value={formData.email} 
            onChange={handleChange} 
            className="px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all w-full" 
          />
          <input 
            name="phone" 
            type="text" 
            required 
            placeholder="Phone Number" 
            value={formData.phone} 
            onChange={handleChange} 
            className="px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all w-full" 
          />
          
          <div className="col-span-1 md:col-span-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 md:gap-4 mt-2">
            <div className="col-span-1 sm:col-span-2">
              <input 
                type="text" 
                name="street" 
                placeholder="Street" 
                value={formData.address.street} 
                onChange={handleChange} 
                className="px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all w-full"
              />
            </div>
            <input 
              type="text" 
              name="city" 
              placeholder="City" 
              value={formData.address.city} 
              onChange={handleChange} 
              className="px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all w-full" 
            />
            <input 
              type="text" 
              name="state" 
              placeholder="State" 
              value={formData.address.state} 
              onChange={handleChange} 
              className="px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all w-full" 
            />
            <input 
              type="text" 
              name="pincode" 
              placeholder="Pincode" 
              value={formData.address.pincode} 
              onChange={handleChange} 
              className="px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all w-full" 
            />
          </div>
        </div>
      </div>

      {/* Education Section */}
      <div className="bg-gray-50 p-4 md:p-6 rounded-lg border border-gray-200">
        <h3 className="text-lg md:text-xl font-semibold mb-4 text-gray-700 flex items-center">
          <span className="bg-blue-600 text-white rounded-full w-6 h-6 inline-flex items-center justify-center mr-2 text-sm">2</span>
          Education Details
        </h3>
        {formData.educationDetails.map((edu, index) => (
          <div key={index} className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4 mt-3 p-3 md:p-4 bg-white rounded-md border border-gray-200">
            <div>
              <label className="block text-xs md:text-sm font-medium text-gray-600 mb-1">Degree</label>
              <input 
                type="text" 
                name="degree" 
                placeholder="e.g. Bachelor of Science" 
                value={edu.degree} 
                onChange={(e) => handleEducationChange(index, e)} 
                className="w-full px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
              />
            </div>
            <div>
              <label className="block text-xs md:text-sm font-medium text-gray-600 mb-1">Institution</label>
              <input 
                type="text" 
                name="institution" 
                placeholder="e.g. Stanford University" 
                value={edu.institution} 
                onChange={(e) => handleEducationChange(index, e)} 
                className="w-full px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
              />
            </div>
            <div>
              <label className="block text-xs md:text-sm font-medium text-gray-600 mb-1">Year</label>
              <input 
                type="text" 
                name="year" 
                placeholder="e.g. 2022" 
                value={edu.year} 
                onChange={(e) => handleEducationChange(index, e)} 
                className="w-full px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
              />
            </div>
          </div>
        ))}
        <button 
          type="button" 
          onClick={addEducation} 
          className="mt-3 md:mt-4 text-blue-600 hover:text-blue-800 font-medium flex items-center text-sm"
        >
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
          </svg>
          Add Education
        </button>
      </div>

      {/* Experience Section */}
      <div className="bg-gray-50 p-4 md:p-6 rounded-lg border border-gray-200">
        <h3 className="text-lg md:text-xl font-semibold mb-4 text-gray-700 flex items-center">
          <span className="bg-blue-600 text-white rounded-full w-6 h-6 inline-flex items-center justify-center mr-2 text-sm">3</span>
          Professional Experience
        </h3>
        {formData.experience.map((exp, index) => (
          <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 mt-3 p-3 md:p-4 bg-white rounded-md border border-gray-200">
            <div>
              <label className="block text-xs md:text-sm font-medium text-gray-600 mb-1">Company</label>
              <input 
                type="text" 
                name="company" 
                placeholder="e.g. Google" 
                value={exp.company} 
                onChange={(e) => handleExperienceChange(index, e)} 
                className="w-full px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
              />
            </div>
            <div>
              <label className="block text-xs md:text-sm font-medium text-gray-600 mb-1">Position</label>
              <input 
                type="text" 
                name="position" 
                placeholder="e.g. Software Engineer" 
                value={exp.position} 
                onChange={(e) => handleExperienceChange(index, e)} 
                className="w-full px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
              />
            </div>
            <div>
              <label className="block text-xs md:text-sm font-medium text-gray-600 mb-1">Years</label>
              <input 
                type="text" 
                name="years" 
                placeholder="e.g. 2019-2022" 
                value={exp.years} 
                onChange={(e) => handleExperienceChange(index, e)} 
                className="w-full px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
              />
            </div>
            <div>
              <label className="block text-xs md:text-sm font-medium text-gray-600 mb-1">Description</label>
              <input 
                type="text" 
                name="description" 
                placeholder="Brief description of your role" 
                value={exp.description} 
                onChange={(e) => handleExperienceChange(index, e)} 
                className="w-full px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
              />
            </div>
          </div>
        ))}
        <button 
          type="button" 
          onClick={addExperience} 
          className="mt-3 md:mt-4 text-blue-600 hover:text-blue-800 font-medium flex items-center text-sm"
        >
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
          </svg>
          Add Experience
        </button>
      </div>

      {/* Portfolio Section */}
      <div className="bg-gray-50 p-4 md:p-6 rounded-lg border border-gray-200">
        <h3 className="text-lg md:text-xl font-semibold mb-4 text-gray-700 flex items-center">
          <span className="bg-blue-600 text-white rounded-full w-6 h-6 inline-flex items-center justify-center mr-2 text-sm">4</span>
          Portfolio &amp; Resume
        </h3>
        <div className="space-y-3 md:space-y-4">
          <div>
            <label className="block text-xs md:text-sm font-medium text-gray-600 mb-1">Resume Link</label>
            <input 
              name="resumeLink" 
              type="url" 
              required 
              placeholder="Google Drive or Dropbox Resume Link" 
              value={formData.resumeLink} 
              onChange={handleChange} 
              className="w-full px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
            />
          </div>
          
          <div>
            <label className="block text-xs md:text-sm font-medium text-gray-600 mb-1">Project Links</label>
            {formData.projectLinks.map((link, index) => (
              <div key={index} className="flex mb-2">
                <input 
                  type="url" 
                  placeholder="Project URL (e.g. GitHub, portfolio)" 
                  value={link} 
                  onChange={(e) => handleProjectLinkChange(index, e)} 
                  className="w-full px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
                />
              </div>
            ))}
            <button 
              type="button" 
              onClick={addProjectLink} 
              className="text-blue-600 hover:text-blue-800 font-medium flex items-center text-sm"
            >
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
              </svg>
              Add Project Link
            </button>
          </div>
        </div>
      </div>

      {/* Cover Letter */}
      <div className="bg-gray-50 p-4 md:p-6 rounded-lg border border-gray-200">
        <h3 className="text-lg md:text-xl font-semibold mb-4 text-gray-700 flex items-center">
          <span className="bg-blue-600 text-white rounded-full w-6 h-6 inline-flex items-center justify-center mr-2 text-sm">5</span>
          Cover Letter
        </h3>
        <textarea 
          name="coverLetter" 
          rows="4" 
          placeholder="Tell us why you're the perfect fit for this position..." 
          value={formData.coverLetter} 
          onChange={handleChange} 
          className="w-full px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
        />
      </div>

      {/* Additional Options */}
      <div className="bg-gray-50 p-4 md:p-6 rounded-lg border border-gray-200">
        <h3 className="text-lg md:text-xl font-semibold mb-4 text-gray-700 flex items-center">
          <span className="bg-blue-600 text-white rounded-full w-6 h-6 inline-flex items-center justify-center mr-2 text-sm">6</span>
          Additional Information
        </h3>
        <div className="space-y-3 md:space-y-4">
          <div className="flex items-center gap-2">
            <input 
              type="checkbox" 
              id="canRelocate"
              name="canRelocate" 
              checked={formData.canRelocate} 
              onChange={handleChange} 
              className="w-4 h-4 md:w-5 md:h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500" 
            />
            <label htmlFor="canRelocate" className="text-gray-700 text-sm md:text-base">
              I am willing to relocate if necessary
            </label>
          </div>
          
          <div>
            <label className="block text-xs md:text-sm font-medium text-gray-600 mb-1">Digital Signature</label>
            <input 
              name="digitalSignature" 
              type="text" 
              required 
              placeholder="Type your full name as signature" 
              value={formData.digitalSignature} 
              onChange={handleChange} 
              className="w-full px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
            />
          </div>
          
          <div className="flex items-center gap-2 mt-3 md:mt-4 bg-blue-50 p-3 md:p-4 rounded-md border border-blue-100">
            <input 
              type="checkbox" 
              id="consent"
              name="consent" 
              checked={formData.consent} 
              onChange={handleChange} 
              required 
              className="w-4 h-4 md:w-5 md:h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500" 
            />
            <label htmlFor="consent" className="text-gray-700 text-sm md:text-base">
              I consent to the processing of my data in accordance with the Privacy Policy
            </label>
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex justify-center pt-2 md:pt-4">
        <button 
          type="submit" 
          className="bg-blue-600 text-white font-semibold py-2 px-6 md:py-3 md:px-8 rounded-md flex items-center gap-2 hover:bg-blue-700 transition-colors shadow-md w-full sm:w-auto justify-center"
        >
          Submit Application
          <svg className="w-4 h-4 md:w-5 md:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path>
          </svg>
        </button>
      </div>
    </form>
  </div>
</section>
  );
};

export default ApplyJob;
