import React, { useState, useEffect } from 'react';
import {  ArrowRight } from 'lucide-react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";

const FeaturedWork = () => {

  
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
      easing: 'ease-in-out'
    });
  }, []);
  
  const projects = [
    {
      id: 1,
      name: 'My Visa Point',
      description: 'MyVisaPoint.com is a comprehensive visa consulting website that provides expert guidance and resources for visa applications and immigration processes.',
      category: 'Web Development',
      image: 'https://ik.imagekit.io/jncw2kb8u/Screenshot%202025-03-22%20172141.png?updatedAt=1742645519958',
      link:"https://myvisapoint.com/"
    },
    {
      id: 2,
      name: 'Video Crafts India',
      description: 'VideoCraftsIndia.com is a dynamic website showcasing premium video production and editing services, tailored for businesses and creators. It highlights expertise in storytelling, cinematography, and post-production to deliver high-quality visual content.',
      category: 'Mobile App',
      image: 'https://ik.imagekit.io/jncw2kb8u/Screenshot%202025-03-22%20172623.png?updatedAt=1742644634648',
      link:"https://videocraftsindia.com/"
    },
   
    {
      id: 3,
      name: 'VST Infra',
      description: 'VSTInfra.com is a professional website showcasing infrastructure and real estate solutions with a modern, user-friendly design. It highlights projects, services, and company expertise to engage clients and investors.',
      category: 'Artificial Intelligence',
      image: 'https://ik.imagekit.io/jncw2kb8u/vstinfra.com_(Surface%20Pro%207).png?updatedAt=1742645224535',
      link:"https://vstinfra.com/"
    },
    {
      id: 4,
      name: 'BMSD Healthcare',
      description: 'BMSDHealthcare.com is a healthcare website providing medical services, patient care solutions, and health consultations with a user-friendly interface.',
      category: 'EdTech',
      image: 'https://ik.imagekit.io/jncw2kb8u/Screenshot%202025-03-22%20174423.png?updatedAt=1742645722808',
      link:"https://bmsdhealthcare.com/"
    },
  ];

  const categories = ['All', 'Web Development', 'Mobile App', 'Data Visualization', 'Artificial Intelligence', 'EdTech', 'Virtual Reality'];
  const [activeCategory, setActiveCategory] = useState('All');

  const filteredProjects = activeCategory === 'All' 
    ? projects 
    : projects.filter(project => project.category === activeCategory);

  return (
    <div className="py-10 md:py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16" data-aos="fade-up">
          <span className="text-doubleBlue font-medium tracking-wider uppercase text-sm">Our Portfolio</span>
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mt-2 mb-6">Exceptional Projects</h2>
          <div className="w-24 h-1 bg-middleBlue mx-auto mb-8 rounded-full"></div>
          <p className="text-lg text-gray-600">
            Discover how we've helped businesses transform their digital presence with
            innovative solutions tailored to their unique needs.
          </p>
        </div>

        <div className="flex flex-wrap justify-center mb-12 gap-2" data-aos="fade-up" data-aos-delay="100">
          {categories.map((category, index) => (
            <button
              key={index}
              onClick={() => setActiveCategory(category)}
              className={`px-5 py-2 rounded-full text-sm font-medium transition-all ${
                activeCategory === category
                  ? 'bg-gradient-to-r from-middleBlue to-extraBlue text-white shadow-lg scale-105'
                  : 'bg-white text-gray-600 hover:bg-gray-100'
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredProjects.map((project, index) => (
            <div 
              key={project.id} 
              className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 group"
             
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <div className="relative overflow-hidden h-56">
                <img 
                  src={project.image} 
                  alt={project.name} 
                  className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end">
                  <div className="p-6 w-full">
                    <span className="inline-block px-3 py-1 bg-darkBlue text-white text-xs rounded-full mb-3">
                      {project.category}
                    </span>
                    <Link
                      to={project.link} 
                      className="text-white font-medium flex items-center text-lg group-hover:text-grey transition-colors"
                    >
                      View Details <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 mb-3 group-hover:text-darkBlue transition-colors">
                  {project.name}
                </h3>
                <p className="text-gray-600">{project.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedWork;