import React, { useState, useEffect, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { Users, Lightbulb, HeartHandshake, Clock, Award, LifeBuoy, ChevronRight, Check, X, ArrowRight, Play, Menu, ExternalLink } from 'lucide-react';

const WhyChooseUs = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const statsRef = useRef(null);
  
  // Animated stats counter
  const [animatedStats, setAnimatedStats] = useState([
    { id: 1, value: 0, target: 10, label: 'Years in Business', suffix: '+', icon: <Clock className="w-6 h-6" /> },
    { id: 2, value: 0, target: 200, label: 'Projects Completed', suffix: '+', icon: <Award className="w-6 h-6" /> },
    { id: 3, value: 0, target: 50, label: 'Expert Team Members', suffix: '+', icon: <Users className="w-6 h-6" /> },
    { id: 4, value: 0, target: 95, label: 'Client Satisfaction', suffix: '%', icon: <HeartHandshake className="w-6 h-6" /> }
  ]);

  // Intersection observer for stats animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );
    
    if (statsRef.current) {
      observer.observe(statsRef.current);
    }
    
    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, []);
  
  // Stats counter animation
  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setAnimatedStats(prev => 
          prev.map(stat => ({
            ...stat,
            value: stat.value < stat.target ? 
              stat.value + Math.ceil(stat.target / 20) > stat.target ? 
                stat.target : stat.value + Math.ceil(stat.target / 20) : 
              stat.target
          }))
        );
      }, 100);
      
      const allDone = animatedStats.every(stat => stat.value === stat.target);
      if (allDone) {
        clearInterval(interval);
      }
      
      return () => clearInterval(interval);
    }
  }, [isVisible, animatedStats]);

  // Testimonial auto-rotation
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTestimonial(prev => (prev + 1) % testimonials.length);
    }, 6000);
    
    return () => clearInterval(interval);
  }, []);

  const differentiators = [
    {
      id: 1,
      title: 'Experienced Team',
      description: 'Our team of seasoned professionals brings years of industry expertise to every project.',
      icon: <Users className="w-10 h-10 text-white" />,
      details: 'Our specialists bring over 5 years of freelance experience along with 1 year of operating as a government-registered company, covering various technology domains. We carefully match team members with projects based on expertise and client needs, ensuring you always work with professionals who understand your unique challenges.',
      benefits: ['Technical expertise', 'Domain knowledge', 'Project management skills', 'Communication excellence'],
      showcase: '87% of our team holds advanced certifications in their specialized domains'
    },
    {
      id: 2,
      title: 'Innovative Solutions',
      description: 'We leverage cutting-edge technologies to deliver forward-thinking solutions.',
      icon: <Lightbulb className="w-10 h-10 text-white" />,
      details: `Our R&D team constantly evaluates emerging technologies and frameworks to incorporate the most efficient, scalable solutions for our clients. We've pioneered implementations in AI, blockchain, and cloud-native architecture that have become industry standards.`,
      benefits: ['Future-proof technology', 'Competitive advantage', 'Scalable architecture', 'Optimized performance'],
      showcase: '12 proprietary innovations developed in the last 24 months'
    },
    {
      id: 3,
      title: 'Client-Centric Approach',
      description: 'We prioritize your needs and goals, ensuring solutions that align with your vision.',
      icon: <HeartHandshake className="w-10 h-10 text-white" />,
      details: 'From initial consultation to final delivery, we maintain open communication channels and involve you in key decisions. Our proprietary client portal gives you real-time project updates, documentation access, and direct communication with your dedicated project manager.',
      benefits: ['Tailored solutions', 'Transparent communication', 'Collaborative development', 'Alignment with business goals'],
      showcase: '98% of clients rate our communication as "excellent"'
    },
    {
      id: 4,
      title: 'Timely Delivery',
      description: 'We commit to project timelines and deliver results when you need them.',
      icon: <Clock className="w-10 h-10 text-white" />,
      details: 'Our agile methodology and sophisticated project management tools enable us to maintain an impressive 94% on-time delivery rate. We implement milestone-based tracking and transparent progress reporting to ensure deadlines are consistently met without compromising quality.',
      benefits: ['Predictable timelines', 'Regular milestone updates', 'Efficient processes', 'No last-minute surprises'],
      showcase: '94% on-time delivery rate across all projects'
    },
    {
      id: 5,
      title: 'Quality Assurance',
      description: 'Rigorous testing and quality control processes ensure flawless implementations.',
      icon: <Award className="w-10 h-10 text-white" />,
      details: 'Our comprehensive QA framework includes automated testing, security scanning, performance optimization, and manual review stages. Every solution undergoes extensive testing in environments that mirror your production systems, with detailed reports documenting all verification procedures.',
      benefits: ['Reduced defects', 'Enhanced security', 'Performance optimization', 'Comprehensive documentation'],
      showcase: '99.8% uptime for solutions in production'
    },
    {
      id: 6,
      title: 'Post-Project Support',
      description: 'Our relationship continues after launch with dedicated maintenance and support.',
      icon: <LifeBuoy className="w-10 h-10 text-white" />,
      details: 'Choose from three tiers of ongoing support plans tailored to your needs. Our support team provides rapid response times (as quick as 2 hours for critical issues), regular maintenance updates, performance monitoring, and quarterly technology reviews to keep your systems optimized.',
      benefits: ['Rapid issue resolution', 'Proactive maintenance', 'Technology optimization', 'Continuous improvement'],
      showcase: 'Average support response time under 4 hours'
    }
  ];

  const testimonials = [
    {
      id: 1,
      text: "Dodun Soft Solution has transformed our digital operations. Their expertise, innovative approach, and commitment to our vision have made them an invaluable partner in our growth journey.",
      author: "Sarah Johnson",
      position: "CTO, Global Enterprises",
      rating: 5,
      industry: "Finance",
      results: "37% increase in operational efficiency"
    },
    {
      id: 2,
      text: "Working with Dodun has been refreshingly different. Their team doesn't just deliver what we ask for—they anticipate needs we hadn't even considered. The solutions they've implemented have measurably improved our operational efficiency by 37%.",
      author: "Michael Chen",
      position: "Operations Director, TechForward",
      rating: 5,
      industry: "Healthcare",
      results: "52% reduction in processing time"
    },
    {
      id: 3,
      text: "After working with several development partners, we finally found our match with Dodun Soft. Their transparent communication, technical excellence, and genuine commitment to our success set them apart. They've become an extension of our team.",
      author: "Priya Sharma",
      position: "Founder, InnovateX",
      rating: 5,
      industry: "E-commerce",
      results: "128% increase in conversion rate"
    }
  ];
  
  const faqs = [
    {
      id: 1,
      question: "What technologies do you specialize in?",
      answer: "We specialize in a wide range of technologies including React, Angular, Vue.js, Node.js, Python, Java, .NET, AWS, Azure, Google Cloud, and more. Our team stays current with emerging technologies to provide cutting-edge solutions tailored to your specific business needs. We select the best-fit technology stack based on your project requirements, scalability needs, and long-term maintenance considerations."
    },
    {
      id: 2,
      question: "How do you ensure project quality?",
      answer: "Our comprehensive quality assurance process includes automated testing, manual testing, code reviews, security audits, and performance testing. We maintain rigorous standards throughout development to deliver flawless solutions. Every project undergoes a multi-stage validation process with client review opportunities at each milestone to ensure alignment with expectations."
    },
    {
      id: 3,
      question: "What is your typical project timeline?",
      answer: "Project timelines vary based on scope and complexity. After initial consultation, we provide detailed project plans with milestones and deliverables. Our agile approach allows for flexibility while maintaining clear timelines and expectations. For typical enterprise projects, we establish 2-4 week sprints with demonstrable progress at each increment."
    },
    {
      id: 4,
      question: "Do you offer ongoing maintenance and support?",
      answer: "Yes, we offer comprehensive maintenance and support packages tailored to your needs. From basic bug fixes to proactive system monitoring and optimization, our support team ensures your solutions continue to perform optimally. We offer different service level agreements (SLAs) to match your business requirements and budget."
    },
    {
      id: 5,
      question: "How do you handle project changes and new requirements?",
      answer: "We understand that requirements evolve. Our agile methodology incorporates change management processes that allow for adjustments while maintaining project control. We document all change requests, analyze impacts on timeline and budget, and work with you to prioritize changes that deliver the most business value."
    }
  ];
  
  const processSteps = [
    {
      step: 1,
      title: "Discovery",
      description: "We deeply analyze your business needs, goals, and existing systems to establish a solid foundation."
    },
    {
      step: 2,
      title: "Planning",
      description: "Together, we create a detailed roadmap with clear milestones, deliverables, and success metrics."
    },
    {
      step: 3,
      title: "Development",
      description: "Our experts build your solution using best practices and cutting-edge technologies."
    },
    {
      step: 4,
      title: "Testing",
      description: "Rigorous quality assurance ensures your solution performs flawlessly in all scenarios."
    },
    {
      step: 5,
      title: "Deployment",
      description: "We carefully implement your solution with minimal disruption to your operations."
    },
    {
      step: 6,
      title: "Support",
      description: "Our relationship continues with dedicated post-launch support and optimization."
    }
  ];

  // Initialize AOS on component mount
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Trigger animation only once
    });
  }, []);

  return (
    <div className="bg-gradient-to-b from-gray-50 via-white to-blue-50">
      
      <div className="container mx-auto px-4 py-4 md:py-12">
        {/* Enhanced Tab System for Services */}
        <div className="mb-32">
          {/* Animated Header Section */}
          <div className="max-w-4xl mx-auto text-center mb-24 relative" data-aos="fade-up">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-32 h-32 bg-blue-500 rounded-full opacity-10"></div>
            <div className="absolute top-0 right-1/4 w-16 h-16 bg-yellow-400 rounded-full opacity-20"></div>
            <div className="absolute bottom-0 left-1/4 w-24 h-24 bg-purple-500 rounded-full opacity-10"></div>
            <div className="inline-block mb-4 relative">
              <div className="h-1 w-24 bg-gradient-to-r from-blue-400 to-darkBlue mx-auto mb-3"></div>
              <span className="text-doubleBlue font-semibold tracking-wider uppercase text-sm bg-blue-50 px-4 py-1 rounded-full">Why Choose Us</span>
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Transforming Visions Into
              <div className="relative inline-block mx-2">
                <span className="relative z-10 text-darkBlue">Digital Reality</span>
                <div className="absolute bottom-0 left-0 w-full h-3 bg-blue-200 opacity-50 z-0"></div>
              </div>
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              At Dodun Soft Solution, we combine cutting-edge technology with strategic expertise to create solutions that empower businesses to thrive in the digital landscape.
            </p>
          </div>

          {/* Tab Buttons */}
          <div className="flex flex-wrap justify-center gap-2 mb-12" data-aos="fade-up">
            {differentiators.map((item) => (
              <button
                key={item.id}
                onClick={() => setActiveTab(item.id)}
                className={`px-5 py-3 rounded-full text-sm font-medium transition-all duration-300 ${
                  activeTab === item.id 
                    ? 'bg-gradient-to-r from-middleBlue to-extraBlue text-white shadow-lg scale-105' 
                    : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
                }`}
              >
                {item.title}
              </button>
            ))}
          </div>

          {/* Active Tab Content */}
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100" data-aos="fade-up">
            <div className="md:flex">
              <div className="md:w-1/3 bg-gradient-to-br from-doubleBlue to-customBlue text-white p-8 md:p-12 flex flex-col justify-center relative overflow-hidden">
                <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full transform translate-x-1/3 -translate-y-1/3"></div>
                <div className="absolute bottom-0 left-0 w-48 h-48 bg-ligthBlue opacity-10 rounded-full transform -translate-x-1/3 translate-y-1/3"></div>
                <div className="mb-8 p-6 rounded-xl bg-white/10 backdrop-blur-sm inline-block relative z-10">
                  {differentiators[activeTab-1].icon}
                </div>
                <h3 className="text-2xl md:text-3xl font-bold mb-4 relative z-10">{differentiators[activeTab-1].title}</h3>
                <p className="text-blue-100 mb-8 relative z-10">{differentiators[activeTab-1].description}</p>
                <div className="bg-blue-900/30 backdrop-blur-sm p-4 rounded-lg relative z-10">
                  <div className="font-medium text-white mb-2">Key Highlight</div>
                  <div className="text-blue-100">{differentiators[activeTab-1].showcase}</div>
                </div>
              </div>

              <div className="md:w-2/3 p-8 md:p-12">
                <div className="h-full flex flex-col justify-center">
                  <h4 className="text-xl font-semibold text-gray-900 mb-6 pb-4 border-b border-gray-100">
                    How we make a difference
                  </h4>
                  <p className="text-gray-600 mb-8">{differentiators[activeTab-1].details}</p>
                  <div className="mb-8">
                    <h5 className="font-medium text-gray-900 mb-4">Core benefits:</h5>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {differentiators[activeTab-1].benefits.map((benefit, index) => (
                        <div key={index} className="flex items-start">
                          <div className="mt-1 mr-3 bg-green-100 rounded-full p-1">
                            <Check className="w-4 h-4 text-green-600" />
                          </div>
                          <span className="text-gray-700">{benefit}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="bg-gradient-to-r from-gray-50 to-blue-50 p-6 rounded-xl border border-blue-100">
                    <div className="flex items-start">
                      <div className="text-darkBlue mr-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                        </svg>
                      </div>
                      <div>
                        <h5 className="font-medium text-gray-900 mb-2">Our commitment to you</h5>
                        <p className="text-gray-600">We don't just deliver technology—we deliver outcomes. Our team is committed to your success, providing solutions that solve real business challenges and create lasting value.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;