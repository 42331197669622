import React, { useState, useEffect } from 'react';
import { Globe, Smartphone, PaintBucket, Cloud, LineChart, Brain, Database, Building2, ArrowRight, Check, Star, ChevronRight, ChevronLeft, Shapes   } from 'lucide-react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';
import { motion } from 'framer-motion';
const ServicesShowcase = () => {
    const [activeTab, setActiveTab] = useState('all');
    const [featuredService, setFeaturedService] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      setIsVisible(true);
      // Set a random featured service on component mount
      const randomIndex = Math.floor(Math.random() * services.length);
      setFeaturedService(services[randomIndex]);
    }, []);
  
    const services = [
      
      {
        id: 2,
        title: 'Mobile App Development',
        category: 'development',
        description: 'Native and cross-platform mobile applications designed to engage users and deliver seamless experiences.',
        features: ['iOS & Android apps', 'Cross-platform solutions', 'App Store optimization', 'Push notifications'],
        icon: <Smartphone className="w-10 h-10 text-indigo-500" />,
        color: 'indigo'
      },
      {
        id: 6,
        title: 'Virtual Tour & 3D Animation',
        category: 'development',
        description: 'Immersive virtual tours and 3D animations to enhance user engagement and showcase spaces dynamically.',
        features: [
          '360° virtual tours',
          '3D modeling & rendering',
          'Interactive walkthroughs',
          'Google Maps integration'
        ],
        icon: <Shapes className="w-10 h-10 text-purple-500" />,  
        color: 'purple'
      },
      {
        id: 1,
        title: 'Web Development',
        category: 'development',
        description: 'Custom websites and web applications built with the latest technologies for optimal performance and user experience.',
        features: ['Responsive design', 'SEO optimization', 'API integration', 'Performance tuning'],
        icon: <Globe className="w-10 h-10 text-blue-500" />,
        color: 'blue',
        popular: true
      },
      {
        id: 3,
        title: 'UI/UX Design',
        category: 'design',
        description: 'User-centric design solutions that combine aesthetics with functionality for intuitive digital experiences.',
        features: ['User research', 'Wireframing', 'Prototyping', 'Usability testing'],
        icon: <PaintBucket className="w-10 h-10 text-purple-500" />,
        color: 'purple',
        popular: true
      },
      {
        id: 4,
        title: 'Cloud Solutions',
        category: 'development',
        description: 'Scalable cloud infrastructure and migration services to enhance performance and reduce operational costs.',
        features: ['AWS/Azure/GCP', 'Cloud migration', 'Serverless architecture', 'Auto-scaling'],
        icon: <Cloud className="w-10 h-10 text-sky-500" />,
        color: 'sky'
      },
      {
        id: 5,
        title: 'Digital Marketing',
        category: 'business',
        description: 'Data-driven marketing strategies that increase visibility, drive traffic, and convert leads into customers.',
        features: ['SEO/SEM', 'Social media marketing', 'Content strategy', 'Conversion optimization'],
        icon: <LineChart className="w-10 h-10 text-teal-500" />,
        color: 'teal'
      },
      {
        id: 7,
        title: 'DevOps Services',
        category: 'development',
        description: 'Streamlined development and operations processes for faster deployment and improved collaboration.',
        features: ['CI/CD pipelines', 'Infrastructure as code', 'Monitoring & logging', 'Containerization'],
        icon: <Database className="w-10 h-10 text-amber-500" />,
        color: 'amber'
      },
      {
        id: 8,
        title: 'Enterprise Solutions',
        category: 'business',
        description: 'Comprehensive enterprise-grade applications that optimize business operations and boost productivity.',
        features: ['Custom ERP systems', 'CRM integration', 'Business intelligence', 'Workflow automation'],
        icon: <Building2 className="w-10 h-10 text-emerald-500" />,
        color: 'emerald'
      }
    ];

    
  return (
    <div className="bg-gray-50 py-16 md:py-24">
    <div className="container mx-auto px-4">
      <div className="max-w-3xl mx-auto text-center mb-12 md:mb-16">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Our Services</h2>
        <div className="w-20 h-1 bg-darkBlue mx-auto mb-6"></div>
        <p className="text-lg text-gray-600">
          We offer a comprehensive range of technology solutions designed to help your business 
          thrive in the digital landscape. Our expertise spans across multiple domains to provide 
          you with end-to-end digital transformation.
        </p>
      </div>

      {/* Featured Service */}
      {featuredService && (
        <div className={`mb-16 bg-gradient-to-br from-gray-900 to-gray-800 rounded-3xl overflow-hidden shadow-2xl transition-all duration-1000 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
          <div className="p-8 md:p-12 flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-8 md:mb-0 md:pr-12">
              <div className="flex items-center mb-6">
                <Star className="h-5 w-5 text-yellow-400 mr-2" />
                <span className="text-yellow-400 font-bold uppercase tracking-wider text-sm">Featured Service</span>
              </div>
              <h3 className="text-3xl md:text-4xl font-bold text-white mb-4">{featuredService.title}</h3>
              <p className="text-gray-300 text-lg mb-6">{featuredService.description}</p>
              <div className="grid grid-cols-2 gap-4 mb-8">
                {featuredService.features.map((feature, index) => (
                  <div key={index} className="flex items-start">
                    <Check className="h-5 w-5 text-green-400 mr-2 shrink-0 mt-0.5" />
                    <span className="text-gray-200">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="md:w-1/2 flex justify-center">
              <div className={`w-64 h-64 rounded-full flex items-center justify-center bg-${featuredService.color}-900/20`}>
                <div className={`w-48 h-48 rounded-full flex items-center justify-center bg-${featuredService.color}-900/40`}>
                  <div className="w-32 h-32 rounded-full flex items-center justify-center bg-white/10 backdrop-blur-sm">
                    {React.cloneElement(featuredService.icon, { className: `w-16 h-16 text-${featuredService.color}-400` })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
        {services.map((service) => (
          <motion.div 
            key={service.id} 
            className="bg-white rounded-xl shadow-md hover:shadow-2xl transition-shadow p-6 border border-gray-100"
            whileHover={{ scale: 1.05, rotateX: -10, rotateY: 10 }}
            transition={{ type: 'spring', stiffness: 200, damping: 10 }}
          >
            <div className="w-16 h-16 flex items-center justify-center bg-gray-50 rounded-lg mb-4">
              {service.icon}
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mb-3">{service.title}</h3>
            <p className="text-gray-600 mb-4">{service.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default ServicesShowcase;