import React from 'react'
import ServicesShowcase from '../components/ServiceShowcase'
import WhyChooseUs from '../components/WhyChooseUs'
import FeaturedWork from '../components/FeautredWork'
import ClientTestimonials from '../components/ClientsTestimonials'
import OurProcess from '../components/OurProcess'
import HeroSection from '../components/HeroSection'
import FAQs from '../components/FAQs'
import HeroHome from '../components/HeroHome'

function Home() {
  return (
    <div>
    <HeroHome/>
    <ServicesShowcase/>
    <WhyChooseUs/>
    <FeaturedWork />
    {/* <ClientTestimonials/> */}
    <OurProcess/>
    <FAQs/>
    </div>
  )
}

export default Home