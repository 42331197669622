import React from 'react';
import { ArrowRight, CheckCircle, Users, Target, TrendingUp, ChevronRight } from 'lucide-react';
import FAQPage from '../components/FAQs';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <div className="bg-white overflow-hidden">
      {/* Hero Section with Animated Background */}
      <div className="relative bg-gradient-to-r from-darkBlue to-extraBlue overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-0">
          <svg className="absolute left-1/2 transform -translate-x-1/2 bottom-0" width="1440" height="400" fill="none">
            <path opacity="0.15" d="M0 266L60 248.7C120 231.3 240 196.7 360 196.7C480 196.7 600 231.3 720 231.3C840 231.3 960 196.7 1080 178.3C1200 160 1320 160 1380 160H1440V400H1380C1320 400 1200 400 1080 400C960 400 840 400 720 400C600 400 480 400 360 400C240 400 120 400 60 400H0V266Z" fill="white" />
            <path opacity="0.1" d="M0 284L60 275.3C120 266.7 240 249.3 360 240.7C480 232 600 232 720 240.7C840 249.3 960 266.7 1080 266.7C1200 266.7 1320 249.3 1380 240.7L1440 232V400H1380C1320 400 1200 400 1080 400C960 400 840 400 720 400C600 400 480 400 360 400C240 400 120 400 60 400H0V284Z" fill="white" />
          </svg>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 md:py-32 relative z-10">
          <div className="md:flex md:items-center md:justify-between">
            <div className="md:w-1/2 mb-10 md:mb-0">
              <span className="inline-block py-1 px-3 text-xs font-semibold bg-customBlue bg-opacity-50 text-blue-100 rounded-full mb-5">ABOUT US</span>
              <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl leading-tight">
                Transforming <span className="text-blue-200">Ideas</span> into Digital <span className="text-blue-200">Reality</span>
              </h1>
              <p className="mt-6 text-xl text-blue-100 max-w-3xl leading-relaxed">
                Discover the story behind Dodun Soft Solution and our commitment to delivering exceptional digital experiences.
              </p>
              <div className="mt-8 flex">
                <a href="#our-story" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-extraBlue bg-white hover:bg-gray-50 shadow-md transition-all duration-300">
                  Our Story
                  <ChevronRight className="ml-2 h-4 w-4" />
                </a>
                <Link
                  to="/contact"
                  className="ml-4 inline-flex items-center justify-center px-5 py-3 border border-white text-base font-medium rounded-md text-white hover:bg-extraBlue hover:bg-opacity-30 transition-all duration-300"
                >
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="md:w-5/12 relative">
              <div className="absolute inset-0 bg-gradient-to-r from-customBlue to-middleBlue transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl opacity-50"></div>
              <img
                src="https://ik.imagekit.io/zfayt6inj/about2.avif?updatedAt=1742662860800"
                alt="Team at work"
                className="relative rounded-lg shadow-2xl w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full h-16 bg-white"></div>
      </div>

      {/* Stats Section */}
      <div className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-center">
            {[
              { number: "1+", label: "Years Experience" },
              { number: "100+", label: "Projects Completed" },
              { number: "50+", label: "Happy Clients" },
              { number: "15+", label: "Team Members" }
            ].map((stat, index) => (
              <div key={index} className="p-6 bg-gray-50 rounded-xl shadow-sm">
                <h3 className="text-3xl md:text-4xl font-bold text-doubleBlue">{stat.number}</h3>
                <p className="mt-2 text-sm md:text-base text-gray-600 font-medium">{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div id="our-story" className="bg-white py-16 md:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-16 items-center">
            <div className="relative col-span-5">
              <div className="relative lg:h-96 rounded-3xl shadow-2xl overflow-hidden">
                <div className="absolute inset-0 bg-doubleBlue mix-blend-multiply opacity-10"></div>
                <img src="https://ik.imagekit.io/zfayt6inj/about.avif?updatedAt=1742662861106" alt="Team collaboration" className="w-full h-full object-cover" />
              </div>
              <div className="absolute -bottom-8 -right-8 bg-darkBlue rounded-2xl p-8 shadow-xl hidden lg:block">
                <h3 className="text-white font-bold text-3xl">1+</h3>
                <p className="text-blue-100">Years of Excellence</p>
              </div>
              <div className="absolute -top-8 -left-8 bg-darkBlue rounded-2xl p-8 shadow-xl hidden lg:block">
                <h3 className="text-white font-bold text-3xl">100+</h3>
                <p className="text-indigo-100">Projects Delivered</p>
              </div>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-7">
              <div className="pl-0 md:pl-10">
                <span className="inline-block py-1 px-3 text-xs font-semibold bg-blue-100 text-doubleBlue rounded-full mb-4">OUR STORY</span>
                <h2 className="text-3xl font-bold text-gray-900 mb-8 relative">
                  Why Dodun Soft Solutions?
                  <span className="absolute bottom-0 left-0 h-1 w-24 bg-doubleBlue -mb-3"></span>
                </h2>
                <p className="mt-6 text-lg text-gray-600 leading-relaxed">
                With over 5 years of freelance experience and 1 year as a government-registered company, our journey has been nothing short of remarkable. From startups to established enterprises, we have successfully delivered tailored solutions across various industries, helping businesses thrive.                </p>
                <p className="mt-4 text-lg text-gray-600 leading-relaxed">
                  When you partner with DODUN Soft Solutions, you choose a team that genuinely cares about your success. We take the time to understand your unique challenges and objectives, crafting tailored solutions that align with your vision.
                </p>
                <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4">
                  {[
                    "Innovative Solutions",
                    "Client-Focused Approach",
                    "Technical Excellence",
                    "Ongoing Support"
                  ].map((point, index) => (
                    <div key={index} className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-doubleBlue mt-1 flex-shrink-0" />
                      <p className="ml-3 text-gray-700">{point}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mission and Vision */}
      <div className="bg-gray-50 py-20 relative overflow-hidden">
        <div className="absolute right-0 top-0 h-full w-1/3 bg-blue-50 transform skew-x-12 -mr-32 hidden lg:block"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16">
            <span className="inline-block py-1 px-3 text-xs font-semibold bg-blue-100 text-doubleBlue rounded-full mb-4">OUR PURPOSE</span>
            <h2 className="text-3xl font-bold text-gray-900 inline-block relative">
              Mission & Vision
              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 h-1 w-24 bg-doubleBlue -mb-3"></span>
            </h2>
          </div>
          <div className="grid md:grid-cols-2 gap-12">
            {/* Mission */}
            <div className="bg-white p-8 rounded-xl shadow-xl transform transition hover:scale-105 duration-300 border-t-4 border-doubleBlue">
              <div className="w-16 h-16 bg-darkBlue rounded-xl -mt-12 mb-6 flex items-center justify-center shadow-lg">
                <Target className="w-8 h-8 text-white" />
              </div>
              <h3 className="text-2xl font-bold text-darkBlue mb-4">Our Mission</h3>
              <p className="text-gray-600 leading-relaxed">
                Our mission is to empower businesses of all sizes by providing exceptional digital solutions that drive growth and success. We believe that every brand has a unique story to tell, and we are here to help you communicate that story effectively to your audience.
              </p>
            </div>

            {/* Vision */}
            <div className="bg-white p-8 rounded-xl shadow-xl transform transition hover:scale-105 duration-300 border-t-4 border-doubleBlue">
              <div className="w-16 h-16 bg-darkBlue rounded-xl -mt-12 mb-6 flex items-center justify-center shadow-lg">
                <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
              </div>
              <h3 className="text-2xl font-bold text-darkBlue mb-4">Our Vision</h3>
              <p className="text-gray-600 leading-relaxed">
                To be a leading digital solutions provider recognized for our commitment to quality, creativity, and client satisfaction. We envision a future where businesses thrive in the digital landscape, leveraging our expertise to achieve their goals.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Our Values */}
      <div className="bg-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <span className="inline-block py-1 px-3 text-xs font-semibold bg-blue-100 text-doubleBlue rounded-full mb-4">WHAT GUIDES US</span>
            <h2 className="text-3xl font-bold text-gray-900 inline-block relative">
              Our Core Values
              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 h-1 w-24 bg-doubleBlue -mb-3"></span>
            </h2>
            <p className="mt-10 text-lg text-gray-600 max-w-3xl mx-auto">
              At DODUN Soft Solutions, we foster a collaborative and inclusive environment where creativity thrives. Our team is committed to continuous learning and professional development, staying ahead of industry trends to provide the best solutions for our clients.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {/* Value 1 */}
            <div className="group">
              <div className="bg-gray-50 rounded-xl p-8 text-center hover:bg-darkBlue hover:shadow-xl transition-all duration-300 h-full">
                <div className="w-16 h-16 bg-blue-100 group-hover:bg-white rounded-full flex items-center justify-center mx-auto mb-6 transition-colors duration-300">
                  <svg className="w-8 h-8 text-doubleBlue group-hover:text-doubleBlue" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 group-hover:text-white mb-4 transition-colors duration-300">Tailored Solutions</h3>
                <p className="text-gray-600 group-hover:text-blue-100 transition-colors duration-300">
                  We customise our services to meet your specific needs, ensuring that you receive the most relevant and effective strategies.
                </p>
              </div>
            </div>

            {/* Value 2 */}
            <div className="group">
              <div className="bg-gray-50 rounded-xl p-8 text-center hover:bg-darkBlue hover:shadow-xl transition-all duration-300 h-full">
                <div className="w-16 h-16 bg-blue-100 group-hover:bg-white rounded-full flex items-center justify-center mx-auto mb-6 transition-colors duration-300">
                  <Users className="w-8 h-8 text-doubleBlue group-hover:text-doubleBlue" />
                </div>
                <h3 className="text-xl font-bold text-gray-900 group-hover:text-white mb-4 transition-colors duration-300">Expert Guidance</h3>
                <p className="text-gray-600 group-hover:text-blue-100 transition-colors duration-300">
                  Our team is here to guide you every step of the way, offering insights and support to help you navigate the digital landscape.
                </p>
              </div>
            </div>

            {/* Value 3 */}
            <div className="group">
              <div className="bg-gray-50 rounded-xl p-8 text-center hover:bg-darkBlue hover:shadow-xl transition-all duration-300 h-full">
                <div className="w-16 h-16 bg-blue-100 group-hover:bg-white rounded-full flex items-center justify-center mx-auto mb-6 transition-colors duration-300">
                  <TrendingUp className="w-8 h-8 text-doubleBlue group-hover:text-doubleBlue" />
                </div>
                <h3 className="text-xl font-bold text-gray-900 group-hover:text-white mb-4 transition-colors duration-300">Results-Driven Approach</h3>
                <p className="text-gray-600 group-hover:text-blue-100 transition-colors duration-300">
                  We focus on delivering measurable results, using data and analytics to inform our strategies and drive growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FAQPage />
      {/* Team Section */}
      {/* <div className="bg-gray-50 py-20 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-50 to-white"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16">
            <span className="inline-block py-1 px-3 text-xs font-semibold bg-blue-100 text-doubleBlue rounded-full mb-4">OUR PEOPLE</span>
            <h2 className="text-3xl font-bold text-gray-900 inline-block relative">
              Meet Our Team
              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 h-1 w-24 bg-doubleBlue -mb-3"></span>
            </h2>
            <p className="mt-10 text-lg text-gray-600 max-w-3xl mx-auto">
              Meet the talented individuals behind Dodun Soft Solution who work tirelessly to deliver exceptional results for our clients.
            </p>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {[1, 2, 3, 4].map((member) => (
              <div key={member} className="group">
                <div className="bg-white rounded-xl overflow-hidden shadow-lg transform transition-all duration-300 group-hover:shadow-2xl group-hover:-translate-y-2">
                  <div className="h-72 bg-gray-200 relative overflow-hidden">
                    <img src={`https://ik.imagekit.io/jncw2kb8u/attractive-male-video-editor-works-with-footage-video-his-personal-computer-having-break-communicating-his-smartphone-he-works-creative-office-studio-home-neon-lights.jpg?updatedAt=1740033687019${240 + member}`} alt="Team member" className="w-full h-full object-cover transition duration-300 group-hover:scale-110" />
                    <div className="absolute inset-0 bg-gradient-to-t from-blue-900 via-transparent opacity-0 group-hover:opacity-60 transition-opacity duration-300"></div>
                    <div className="absolute inset-x-0 bottom-0 p-4 flex justify-center space-x-3 translate-y-full group-hover:translate-y-0 transition-transform duration-300">
                      {['#', '#', '#'].map((_, idx) => (
                        <a key={idx} href="#" className="bg-white p-2 rounded-full text-doubleBlue hover:text-blue-800 hover:bg-blue-50 transition-colors duration-200">
                          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M12.316 10.051a2.264 2.264 0 01-2.264-2.264 2.264 2.264 0 012.264-2.264A2.264 2.264 0 0114.58 7.787a2.264 2.264 0 01-2.264 2.264m0-7.787a5.524 5.524 0 100 11.047 5.524 5.524 0 000-11.047" clipRule="evenodd" />
                          </svg>
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="p-6">
                    <h3 className="text-lg font-bold text-gray-900">Team Member {member}</h3>
                    <p className="text-doubleBlue font-medium">Position Title</p>
                    <p className="mt-3 text-sm text-gray-500">Passionate about creating innovative solutions that transform businesses.</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          <div className="mt-16 text-center">
            <a href="#" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-doubleBlue hover:bg-blue-700 shadow-md transition-all duration-300">
              Join Our Team
              <ArrowRight className="ml-2 h-4 w-4" />
            </a>
          </div>
        </div>
      </div>
       */}
      {/* Testimonials */}
      {/* <div className="bg-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <span className="inline-block py-1 px-3 text-xs font-semibold bg-blue-100 text-doubleBlue rounded-full mb-4">TESTIMONIALS</span>
            <h2 className="text-3xl font-bold text-gray-900 inline-block relative">
              What Our Clients Say
              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 h-1 w-24 bg-doubleBlue -mb-3"></span>
            </h2>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            {[1, 2, 3].map((index) => (
              <div key={index} className="bg-gray-50 p-8 rounded-xl shadow-lg relative">
                <div className="absolute top-0 left-10 transform -translate-y-1/2 text-doubleBlue">
                  <svg className="h-12 w-12 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
                  </svg>
                </div>
                <div className="pt-8">
                  <p className="text-gray-600 italic mb-6">
                    "Dodun Soft Solution delivered exceptional results for our project. Their team was professional, responsive, and truly understood our business needs."
                  </p>
                  <div className="flex items-center">
                    <div className="h-12 w-12 rounded-full bg-gray-300 mr-4">
                      <img src={`https://ik.imagekit.io/jncw2kb8u/attractive-male-video-editor-works-with-footage-video-his-personal-computer-having-break-communicating-his-smartphone-he-works-creative-office-studio-home-neon-lights.jpg?updatedAt=1740033687019/${100 + index}`} alt="Client" className="h-full w-full object-cover rounded-full" />
                    </div>
                    <div>
                      <h4 className="font-bold text-gray-900">Client Name</h4>
                      <p className="text-sm text-doubleBlue">Position, Company</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* CTA Section */}
      {/* <div className="bg-doubleBlue relative overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <svg className="absolute bottom-0 left-0 h-full w-full transform translate-x-1/2" fill="none" viewBox="0 0 800 800">
            <circle cx="400" cy="400" r="400" fill="url(#gradient)" fillOpacity="0.1" />
            <defs>
              <radialGradient id="gradient" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(400 400) rotate(90) scale(400)">
                <stop stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div> */}
    </div>
  );
};

export default AboutUs;