import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const FAQPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-out',
      mirror: true
    });
  }, []);

  const faqs = [
    {
      question: "Why do I need digital marketing for my business?",
      answer: [
        "Having a website is not enough these days. The internet has become one of the most competitive spaces and everyone is competing with the mass amounts of traffic and users online. While a website is extremely important and serves as the foundation for your online marketing presence, it is just that, a foundation. Promotion of your website via other digital marketing channels is important to set your business apart and give you every opportunity to attract a new visitors, which will hopefully turn into new customer.",
        "Digital marketing is the promotion of your brand and the service you need to battle against competitors that do not only exist locally, but now exist nationally and internationally against your business.",
        "With a strong online presence and digital marketing strategy, the opportunity to scale your business successfully is enormous. Digital marketing presents opportunity for exponential business growth and return multiples that are better than any other advertising opportunity.",
      ],
      category: "Digital Marketing",
    },
    {
      question: "How often do I need to update my business website?",
      answer: [
        "With how fast technology changes, it is recommended to audit your website at least once every two years. When designing a website, be sure to keep in mind developing a design that will withstand the test of time. We abide by the idea that simple and minimalistic design that delivers information quickly and easily will always be in.",
        "If you are not sure whether your website needs to be updated, request one of our free website audits that will identify potential areas of improvement and issues that you might be having with your website technology."
      ],
      category: "Web Design",
    },
    {
      question: "Will my business website be optimized for different sizes?",
      answer: [
        "Yes, all of our website designs and development of websites will be optimized for the five standard screen sizes (large desktop, laptop, tablet, phablet, and mobile phone). Over 50% of website traffic comes from mobile devices so we put a huge emphasis on making sure that your website performs on every single screen that someone might be viewing your site on."
      ],
      category: "Web Design",
    },
    {
      question: "What's the benefit of having a Search Engine Optimization strategy?",
      answer: [
        "An SEO strategy is one that is going to pay dividends down the road. We like to use this analogy for SEO efforts:",
        "Imagine trying to push a large boulder. At first, it is extremely hard to get the giant object that is not moving to start moving. But, once you start to apply more and more pressure and energy, the boulder slowly begins to move. Once the boulder starts to move it becomes easier and easier to get the boulder to move faster and faster. At some point, if you're not careful, the boulder will be rolling out of control. This is the same as SEO efforts.",
        "Earning SEO rankings do not happen overnight because there are many other competitors that are either better positioned or have been putting in SEO efforts for a longer period of time. But, once your website starts to see increased rankings then it is really like a chain-effect. Traffic will increase more and more as we place you higher in search rankings and things will start to grow exponentially month-over-month as we continue to add more and more relevant content and information that leads back to your website."
      ],
      category: "SEO",
    },
    {
      question: "Why is SEO so important?",
      answer: [
        "SEO is important because it helps to make your presence and visibility online much more prominent. When performing SEO efforts, we are creating valuable information and social proof that leads online users back to your business. This inbound marketing strategy is so incredibly valuable and will allow you to reach potential customers that you might not have ever reached in the past. A strong SEO strategy can take your business from zero to one hundred and create an impenetrable force online.",
      ],
      category: "SEO",
    },
    {
      question: "What network do you perform Pay-Per-Click Advertising services on?",
      answer: [
        "We perform pay-per-click advertising services for the following search ad networks: Google Search and Display Network, YouTube Ad Network, Bing Ads Network, Facebook Advertising, Instagram Advertising, Twitter Advertising, and LinkedIn Advertising.",
      ],
      category: "Advertising",
    },
  ];

  // Get unique categories
  const categories = [...new Set(faqs.map(faq => faq.category))];
  const [activeCategory, setActiveCategory] = useState('All');
  const [openFaqId, setOpenFaqId] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaqId(openFaqId === index ? null : index);
  };

  const filteredFaqs = activeCategory === 'All' 
    ? faqs 
    : faqs.filter(faq => faq.category === activeCategory);

  return (
    <div className="min-h-screen bg-white">
      
      {/* Content */}
      <div className="relative z-10 max-w-5xl mx-auto px-4 pt-16 pb-24">
        
        {/* Header */}
        <header className="text-center mb-16" data-aos="fade-down">
          <div className="inline-block mb-3 px-4 py-1 rounded-full bg-blue-50 text-darkBlue text-sm font-medium">Knowledge Base</div>
          <h1 className="text-4xl md:text-6xl font-serif font-light text-slate-800 tracking-tight mb-4">Frequently Asked Questions</h1>
          <p className="text-slate-500 max-w-2xl mx-auto">Find quick answers to common questions about our services</p>
        </header>
        
        {/* Category Filters */}
        <div className="flex flex-wrap justify-center gap-2 mb-12" data-aos="fade-up" data-aos-delay="100">
          <button 
            onClick={() => setActiveCategory('All')}
            className={`px-5 py-2 rounded-full text-sm font-medium transition-colors ${
              activeCategory === 'All' 
                ? 'bg-slate-900 text-white' 
                : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
            }`}
          >
            All Questions
          </button>
          {categories.map(category => (
            <button 
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`px-5 py-2 rounded-full text-sm font-medium transition-colors ${
                activeCategory === category 
                  ? 'bg-slate-900 text-white' 
                  : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
              }`}
            >
              {category}
            </button>
          ))}
        </div>
        
        {/* FAQ List */}
        <div className="space-y-6 mb-16">
          {filteredFaqs.map((faq, index) => (
            <div 
              key={index}
              data-aos="fade-up"
              data-aos-delay={index * 50}
              className="border-b border-slate-200 pb-4"
            >
              <button 
                onClick={() => toggleFaq(index)}
                className="w-full flex items-center justify-between py-4 text-left focus:outline-none group"
              >
                <div className="flex items-start">
                <div className="text-xl">{faq.icon}</div>
                  <h3 className="text-xl font-medium text-slate-800 group-hover:text-darkBlue transition-colors ml-2">
                    {faq.question} 
                    <span className="text-xs font-medium text-darkBlue bg-blue-50 px-2.5 py-0.5 rounded mr-3 mt-1 ml-2">{faq.category}</span>
                  </h3>
                </div>
                <div className={`flex-shrink-0 ml-4 transition-transform duration-300 ${openFaqId === index ? 'rotate-45' : ''}`}>
                  <svg className="w-5 h-5 text-slate-500" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M12 5v14m-7-7h14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </button>
              
              <div 
                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                  openFaqId === index ? 'max-h-screen opacity-100 pt-3' : 'max-h-0 opacity-0'
                }`}
              >
                <div className="ml-11 text-slate-600 space-y-4 pb-2">
                  {Array.isArray(faq.answer) && faq.answer.map((paragraph, i) => (
                    <p key={i} className="leading-relaxed text-justify">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>


      {/* CTA Section */}
      <div className="max-w-4xl mx-auto mt-20" data-aos="fade-up">
        <div className="bg-white rounded-2xl shadow-xl p-8 md:p-12 text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Still have questions?</h2>
          <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
            Our team of digital marketing experts is ready to answer any questions you may have about our services.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
  <Link to="/contact">
    <button className="bg-darkBlue hover:bg-extraBlue text-white font-medium py-3 px-8 rounded-full transition-colors duration-300 shadow-md">
      Contact Us
    </button>
  </Link>
</div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default FAQPage;