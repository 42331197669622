import { useRef, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import { Float, Text } from '@react-three/drei';

// Emoji icons and colors
const TECH_ICONS = [
  '💻', '🖥️', '📱', '🔒', '☁️', '🌐', '📊', '🤖',
  '🔍', '📈', '💳', '🔌', '📡', '🛒', '🔄', '💾',
  '🖱️', '📧', '🖨️', '🧩',
];

const TECH_COLORS = [
  '#ffffff', '#f0f0f0', '#e0e0e0', '#cccccc', '#b0bec5',
  '#cfd8dc', '#e1f5fe', '#eceff1', '#fafafa', '#eeeeee',
];

// Grid configuration (adjust for density)
const GRID_ROWS = 8;
const GRID_COLS = 16;
const FONT_SIZE = 0.8;

const generateNonOverlappingIcons = () => {
  const icons = [];
  const usedCells = new Set();

  for (let row = 0; row < GRID_ROWS; row++) {
    for (let col = 0; col < GRID_COLS; col++) {
      const key = `${row},${col}`;
      usedCells.add(key);

      const x = col * 2 - (GRID_COLS - 1); // Spread across [-width, +width]
      const y = (GRID_ROWS - 1 - row) * 1.8 - ((GRID_ROWS - 1) * 0.9); // Spread across [-height, +height]
      icons.push({
        position: [x + Math.random() * 0.5 - 0.25, y + Math.random() * 0.3 - 0.15, (Math.random() - 0.5) * 5],
        icon: TECH_ICONS[Math.floor(Math.random() * TECH_ICONS.length)],
        color: TECH_COLORS[Math.floor(Math.random() * TECH_COLORS.length)],
        speed: Math.random() * 0.5 + 0.3,
        rotationIntensity: Math.random() * 0.3 + 0.2,
        floatIntensity: Math.random() * 0.5 + 0.3,
      });
    }
  }

  return icons;
};

const FloatingIcons = ({ mouse = { x: 0, y: 0 }, isHovered = false }) => {
  const groupRef = useRef();
  const icons = useMemo(generateNonOverlappingIcons, []);

  const timerRef = useRef(0);

  useFrame((_, delta) => {
    if (groupRef.current) {
      const targetX = -mouse.y * 0.05;
      const targetY = mouse.x * 0.05;
      const lerpSpeed = isHovered ? 0.02 : 0.005;

      groupRef.current.rotation.x += (targetX - groupRef.current.rotation.x) * lerpSpeed;
      groupRef.current.rotation.y += (targetY - groupRef.current.rotation.y) * lerpSpeed;

      timerRef.current += delta * 0.1;
      groupRef.current.rotation.y += Math.sin(timerRef.current) * 0.0005;
      groupRef.current.rotation.x += Math.cos(timerRef.current) * 0.0002;
    }
  });

  return (
    <group ref={groupRef} position={[0, 0, 0]}>
      {icons.map((item, i) => (
        <Float
          key={i}
          speed={item.speed}
          rotationIntensity={item.rotationIntensity}
          floatIntensity={item.floatIntensity}
          floatingRange={[0.1, 0.4]}
        >
          <Text
            position={item.position}
            fontSize={FONT_SIZE}
            color={item.color}
            anchorX="center"
            anchorY="middle"
            material-transparent
            material-opacity={0.65}
          >
            {item.icon}
          </Text>
        </Float>
      ))}
    </group>
  );
};

export default FloatingIcons;
