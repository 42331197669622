import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import CareerPage from "./pages/Careers";
import Services from "./pages/Services";
import ApplyJob from "./pages/ApplyJob";
import ScrollToTop from "./components/ScrollToTop";
import WordSearch from "./pages/WordSearch";

function Layout() {
  return (
    <div>
      <Navbar />
      <ScrollToTop />
      <div className="layout-body">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/careers" element={<CareerPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/wordgame" element={<WordSearch />} />
          <Route path="/industries" element={<Services />} />
          <Route path="/apply/:jobId" element={<ApplyJob />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
