// HeroSection.js
import React, { useState, useRef, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import GradientBackground from "./GradientBackground";
import FloatingIcons from "./FloatingCubes";

const HeroSection = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [mouse, setMouse] = useState({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    checkMobile();

    // Add event listener for window resize
    window.addEventListener("resize", checkMobile);

    // Cleanup
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const handleMouseMove = (e) => {
    if (containerRef.current && !isMobile) {
      const rect = containerRef.current.getBoundingClientRect();
      setMouse({
        x: ((e.clientX - rect.left) / rect.width) * 2 - 1,
        y: -((e.clientY - rect.top) / rect.height) * 2 + 1,
      });
    }
  };

  return (
    <div
      ref={containerRef}
      className="relative w-full  overflow-hidden bg-gradient-to-b from-blue-50 to-white"
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* 3D Background */}
      <div className="absolute inset-0 z-0">
        <Canvas>
          <PerspectiveCamera
            makeDefault
            position={[0, 0, isMobile ? 20 : 15]}
            fov={isMobile ? 70 : 60}
          />
          <GradientBackground
            topColor="#2AA4CD"
            bottomColor="#ffffff"
            speed={0.0002}
          />
          <FloatingIcons
            mouse={mouse}
            isHovered={isHovered}
            isMobile={isMobile}
          />
          <OrbitControls
            enableZoom={false}
            enablePan={false}
            enableRotate={false}
          />
        </Canvas>
      </div>

      {/* Hero Content */}
      <div className="relative z-10 flex flex-col items-center justify-center min-h-screen py-12 px-4 sm:px-6 max-w-6xl mx-auto text-center">
        <div className="backdrop-blur-md bg-white/60 p-6 sm:p-10 rounded-2xl shadow-2xl border border-white/30 w-full max-w-4xl">
          {/* Styled quote marks - hidden on small mobile */}
          <div className="relative">
            <span className="absolute -top-10 sm:-top-16 -left-2 sm:-left-8 text-5xl sm:text-8xl text-blue-400 opacity-30 font-serif hidden sm:block">
              "
            </span>
            <span className="absolute -bottom-10 sm:-bottom-20 -right-2 sm:-right-8 text-5xl sm:text-8xl text-blue-400 opacity-30 font-serif hidden sm:block">
              "
            </span>

            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4 sm:mb-6 text-gray-800 tracking-tight leading-tight">
              <span className="text-middleBlue">
              Empowering Industries with Tailored Digital Solutions
              </span>
            </h1>

            <div className="w-16 sm:w-24 h-1 bg-blue-100 mx-auto my-4 sm:my-6"></div>

            <p className="text-lg sm:text-xl md:text-2xl max-w-3xl mx-auto mb-6 sm:mb-8 text-gray-700 leading-relaxed">
            Custom software and digital marketing solutions tailored to drive growth across industries.
            </p>
          </div>
        </div>

        {/* Trust indicators - Responsive version */}
      </div>

      {/* Enhanced scrolling indicator - responsive version */}
    </div>
  );
};

export default HeroSection;
