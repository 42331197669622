import { useState, useEffect } from "react";


export default function HeroHome() {
  const [isVisible, setIsVisible] = useState(false);
  

  useEffect(() => {
    setIsVisible(true);

    const interval = setInterval(() => {
    
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative py-36 w-full overflow-hidden">
      {/* Video Background with Overlay */}
      <video
        autoPlay
        loop
        muted
        playsInline webkit-playsinline
        className="absolute top-0 left-0 min-h-full min-w-full object-cover"
      >
        <source
          src="https://ik.imagekit.io/devhm/My%20Movie.mp4?updatedAt=1742662020190"
          type="video/mp4"
        />
      </video>

      {/* Gradient Overlay - Using dark blue and sky blue */}
      <div className="absolute inset-0 bg-gradient-to-br from-sky-800 via-black to-blue-350 opacity-80"></div>

      {/* Particle Grid Effect */}
      <div className="absolute inset-0 opacity-10">
        <div
          className="h-full w-full bg-[radial-gradient(white_1px,transparent_1px)]"
          style={{ backgroundSize: "30px 30px" }}
        ></div>
      </div>

      {/* Main Content - Full Width Container */}
      <div className="relative z-10 flex h-full w-full flex-col items-center justify-center px-4 sm:px-6 md:px-8">
        <div
          className={`w-full max-w-7xl transition-all duration-1000 ${
            isVisible ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
          }`}
        >
          {/* Mobile-Optimized Content Layout */}
          <div className="flex flex-col items-center text-center">
            {/* Floating badge - Sky Blue */}
            <div className="mb-4 rounded-full bg-sky-500 bg-opacity-20 px-4 py-2 text-xs font-semibold uppercase tracking-wider text-white backdrop-blur-sm sm:mb-6 sm:text-sm">
              Next Generation Solutions
            </div>

            {/* Main heading - White with Sky Blue accent */}
            <h1 className="mb-3 text-4xl font-extrabold leading-tight tracking-tight text-white sm:text-5xl md:text-6xl lg:text-7xl">
              <span className="relative inline-block">
                Innovative
                <span
                  className="absolute bottom-1 left-0 h-2 w-0 bg-sky-400 opacity-70 transition-all duration-1000"
                  style={{ width: isVisible ? "100%" : "0" }}
                ></span>
              </span>{" "}
              Solutions <br className="hidden sm:block" />
              <span className="bg-gradient-to-r from-sky-300 to-white bg-clip-text text-transparent">
                Empowering Business
              </span>
            </h1>

            {/* Subheading - White */}
            <p className="mb-8 max-w-xl text-base font-light leading-relaxed text-white opacity-90 sm:mb-10 sm:text-lg md:max-w-2xl md:text-xl">
              Transform your digital presence with our cutting-edge technology
              and strategic expertise that delivers measurable growth and ROI.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
