import React, { useState, useEffect } from "react";
import {
  ArrowRight,
  Briefcase,
  Check,
  ChevronDown,
  Mail,
  MapPin,
  Phone,
  Send,
  Users,
  Star,
  Coffee,
  Globe,
} from "lucide-react";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

const CareerPage = () => {
  const [jobs, setJobs] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    resume: null,
    jobPosition: "",
    message: "",
  });

  const [expanded, setExpanded] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleApplyClick = (jobId) => {
    navigate(`/apply/${jobId}`);
  };
  // Properly initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
      mirror: true,
      easing: "ease-out-cubic",
    });

    console.log("AOS initialized with enhanced settings");

    return () => {
      console.log("AOS cleanup");
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      resume: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Here you would typically send the data to your server
    setSubmitted(true);
  };

  const toggleJobDetails = (id) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id],
    });
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const res = await axios.get("https://api.dodunsoftsolutions.com/api/jobs");
        const formattedJobs = res.data.map((job) => ({
          id: job._id,
          title: job.jobTitle || "Untitled Job",
          type: job.jobType || "Unknown",
          location: job.location || "Not specified",
          department: job.role || "General",
          salary: job.salary || "Not mentioned",
          description: job.jobDescription || "",
          responsibilities: job.requiredSkills || [],
          requirements: job.desiredSkills || [],
          benefits: job.benefits || [
            "Supportive team",
            "Good environment",
        ],
        }));
        setJobs(formattedJobs);
      } catch (err) {
        console.error("Failed to fetch jobs:", err);
      }
    };

    fetchJobs();
  }, []);

  const filteredJobs =
    activeTab === "all" ? jobs : jobs.filter((job) => job.type === activeTab);

  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "UI/UX Designer",
      image: "/api/placeholder/80/80",
      quote:
        "Working at Dodun has been the highlight of my career. The collaborative environment and focus on innovation allow me to do my best work every day.",
    },
    {
      name: "Michael Chen",
      role: "SEO Specialist",
      image: "/api/placeholder/80/80",
      quote:
        "I've grown more professionally in my time at Dodun than in all my previous roles combined. The leadership team truly invests in employee development.",
    },
    {
      name: "Priya Patel",
      role: "Product Manager",
      image: "/api/placeholder/80/80",
      quote:
        "The culture at Dodun strikes the perfect balance between ambition and work-life balance. I feel valued as both a professional and a person.",
    },
  ];

  return (
    <div className="bg-white min-h-screen font-sans">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-r from-darkBlue to-extraBlue text-white overflow-hidden">
        {/* Background pattern */}
        <div className="absolute inset-0 opacity-10">
          <svg className="w-full h-full" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
            <defs>
              <pattern id="grid" width="10" height="10" patternUnits="userSpaceOnUse">
                <path d="M 10 0 L 0 0 0 10" fill="none" stroke="white" strokeWidth="0.5" />
              </pattern>
            </defs>
            <rect width="100" height="100" fill="url(#grid)" />
          </svg>
        </div>
        
        {/* Main hero content */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 md:py-28 relative z-10">
          <div className="md:flex md:items-center md:justify-between">
            <div className="md:w-1/2" data-aos="fade-right" data-aos-duration="1000">
              <span className="inline-block px-3 py-1 bg-white/20 backdrop-blur-sm text-white rounded-full text-sm font-semibold mb-3">
                We're Hiring!
              </span>
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight mb-4">
                Join Our Team & <span className="text-blue-200">Build The Future</span>
              </h1>
              <p className="text-lg md:text-xl text-blue-100 mb-8 max-w-lg">
                At Dodun, we're looking for exceptional talent to help us build innovative solutions that make a difference.
              </p>
              
              <div className="flex flex-wrap gap-4">
              <Link 
  to="/about" 
  className="inline-flex items-center px-6 py-3 border border-white text-base font-medium rounded-lg shadow-sm text-white bg-transparent hover:bg-white/10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition-all"
>
  Learn About Us
</Link>
              </div>
            </div>
          </div>
          
          {/* Wave shape divider */}
          <div className="absolute bottom-0 left-0 right-0 h-12 md:h-16 overflow-hidden">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none" className="absolute bottom-0 w-full h-full">
              <path fill="#ffffff" d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="relative block"></path>
              <path fill="#ffffff" d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="relative block"></path>
              <path fill="#ffffff" d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="relative block"></path>
            </svg>
          </div>
        </div>
      </section>
      
      {/* About Section */}
      <section id="about" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16" data-aos="fade-up" data-aos-duration="800">
            <span className="inline-block px-3 py-1 bg-blue-100 text-doubleBlue rounded-full text-sm font-semibold mb-3">Why Choose Us</span>
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Life at Dodun</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              At Dodun, we believe in creating an environment where innovation
              thrives, people grow, and work feels meaningful.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div
              className="bg-blue-50 p-8 rounded-xl"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <div className="bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center mb-6">
                <Users className="h-8 w-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                Collaborative Culture
              </h3>
              <p className="text-gray-600 mb-4">
                Work with talented individuals who are passionate about making a
                difference through technology.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-blue-600 mr-2 mt-0.5" />
                  <span>Cross-functional team collaboration</span>
                </li>
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-blue-600 mr-2 mt-0.5" />
                  <span>Regular team-building events</span>
                </li>
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-blue-600 mr-2 mt-0.5" />
                  <span>Open and transparent communication</span>
                </li>
              </ul>
            </div>

            <div
              className="bg-purple-50 p-8 rounded-xl"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
            >
              <div className="bg-purple-100 rounded-full w-16 h-16 flex items-center justify-center mb-6">
                <Star className="h-8 w-8 text-purple-600" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                Growth Opportunities
              </h3>
              <p className="text-gray-600 mb-4">
                We invest in your professional development with mentorship,
                training, and advancement paths.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-purple-600 mr-2 mt-0.5" />
                  <span>Personalized career development plans</span>
                </li>
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-purple-600 mr-2 mt-0.5" />
                  <span>Training budget for conferences & courses</span>
                </li>
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-purple-600 mr-2 mt-0.5" />
                  <span>Internal mobility and promotion pathways</span>
                </li>
              </ul>
            </div>

            <div
              className="bg-green-50 p-8 rounded-xl"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="300"
            >
              <div className="bg-green-100 rounded-full w-16 h-16 flex items-center justify-center mb-6">
                <Coffee className="h-8 w-8 text-green-600" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                Work-Life Balance
              </h3>
              <p className="text-gray-600 mb-4">
                Flexible schedules, remote options, and a focus on well-being to
                help you thrive both in and out of work.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-green-600 mr-2 mt-0.5" />
                  <span>Flexible work arrangements</span>
                </li>
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-green-600 mr-2 mt-0.5" />
                  <span>Generous PTO and wellness days</span>
                </li>
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-green-600 mr-2 mt-0.5" />
                  <span>Mental health resources and support</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Job Openings Section */}
      <section id="openings" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16" data-aos="fade-up" data-aos-duration="800">
            <span className="inline-block px-3 py-1 bg-blue-100 text-doubleBlue rounded-full text-sm font-semibold mb-3">Opportunities</span>
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Join Our Team</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              Explore our current openings and find where your skills and
              passion align with our mission.
            </p>
          </div>

          {/* Job Filter Tabs */}
          <div
            className="flex justify-center mb-8"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            <div className="inline-flex rounded-md shadow-sm bg-white p-1 border border-gray-200">
              <button
                className={`px-6 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
                  activeTab === "all"
                    ? "bg-darkBlue text-white shadow-lg"
                    : "bg-white text-gray-700 hover:bg-gray-100"
                }`}
                onClick={() => setActiveTab("all")}
              >
                All Positions
              </button>
             
            </div>
          </div>

          {/* Job Listings */}
          <div className="space-y-6">
            {filteredJobs.map((job, index) => (
              <div
                key={job.id}
                className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-100 transition-all duration-300 hover:shadow-lg"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay={index * 100}
              >
                <div
                  className="p-6 cursor-pointer"
                  onClick={() => toggleJobDetails(job.id)}
                >
                  <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                    <div>
                      <div className="flex items-center">
                        <h3 className="text-xl font-bold text-gray-900">
                          {job.title}
                        </h3>
                        <span className="ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-doubleBlue">
                          {job.type === "ui/ux" ? "Design" : "Marketing"}
                        </span>
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 flex-wrap gap-4">
                        <div className="flex items-center">
                          <MapPin className="h-4 w-4 mr-1" />
                          {job.location}
                        </div>
                        <div className="flex items-center">
                          <Briefcase className="h-4 w-4 mr-1" />
                          {job.department}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center mt-4 md:mt-0">
                      <span className="text-sm text-gray-500 mr-3">
                        {expanded[job.id] ? "Hide Details" : "Show Details"}
                      </span>
                      <ChevronDown
                        className={`h-5 w-5 text-gray-500 transform transition-transform ${
                          expanded[job.id] ? "rotate-180" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <p className="mt-3 text-gray-600">{job.description}</p>
                </div>

                {expanded[job.id] && (
                  <div
                    className="px-6 pb-6 border-t border-gray-200 pt-6"
                    data-aos="fade-down"
                    data-aos-duration="500"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                      <div>
                        <h4 className="text-lg font-semibold text-gray-900 mb-3">
                          Responsibilities:
                        </h4>
                        <ul className="space-y-2">
                          {job.responsibilities.map((item, i) => (
                            <li key={i} className="flex items-start">
                              <Check className="h-5 w-5 text-green-500 mr-2 mt-0.5" />
                              <span className="text-gray-600">{item}</span>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div>
                        <h4 className="text-lg font-semibold text-gray-900 mb-3">
                          Requirements:
                        </h4>
                        <ul className="space-y-2">
                          {job.requirements.map((item, i) => (
                            <li key={i} className="flex items-start">
                              <Check className="h-5 w-5 text-blue-500 mr-2 mt-0.5" />
                              <span className="text-gray-600">{item}</span>
                            </li>
                          ))}
                        </ul>

                        <h4 className="text-lg font-semibold text-gray-900 mt-6 mb-3">
                          Benefits:
                        </h4>
                        <ul className="space-y-2">
                          {job.benefits.map((item, i) => (
                            <li key={i} className="flex items-start">
                              <Star className="h-5 w-5 text-yellow-500 mr-2 mt-0.5" />
                              <span className="text-gray-600">{item}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="flex justify-end mt-8">
                      <button
                        onClick={() => handleApplyClick(job.id)} // or job.id
                        className="inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-darkBlue hover:bg-middleBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all"
                      >
                        Apply for this Position{" "}
                        <ArrowRight className="ml-2 h-4 w-4" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Application Form */}
    </div>
  );
};
export default CareerPage;
