import React, { useState } from "react";
import axios from 'axios';


const ContactPage = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await axios.post("https://api.dodunsoftsolutions.com/api/enquiries", formData);
            console.log("Form submitted:", response.data);
            alert("Thank you for reaching out! We will get back to you soon.");
            setIsSubmitted(true);
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                message: "",
            });
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Failed to submit form. Please try again later.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="bg-white">
            {/* Hero Section with Animated Gradient Background */}{/* Hero Section with Animated Gradient Background */}
            <div className="relative overflow-hidden bg-gradient-to-br from-blue-50 via-indigo-50 to-white">
                {/* Animated Background Elements */}
                <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
                    <div className="absolute -top-24 -right-24 w-96 h-96 bg-gradient-to-br from-customBlue/20 to-middleBlue/20 rounded-full blur-3xl transform animate-pulse"></div>
                    <div className="absolute top-1/2 -left-48 w-80 h-80 bg-gradient-to-tr from-doubleBlue/10 to-middleBlue/10 rounded-full blur-3xl transform animate-pulse" style={{ animationDuration: '8s', animationDelay: '1s' }}></div>
                    <div className="absolute -bottom-32 left-1/3 w-64 h-64 bg-gradient-to-br from-middleBlue/20 to-customBlue/20 rounded-full blur-3xl transform animate-pulse" style={{ animationDuration: '10s' }}></div>
                </div>

                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 md:py-28 relative z-10">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                        <div className="text-center lg:text-left">
                            <h1 className="text-4xl md:text-5xl font-extrabold tracking-tight">
                                <span className="block text-gray-900">Get in Touch</span>
                                <span className="block mt-2 bg-gradient-to-r from-customBlue to-middleBlue bg-clip-text text-transparent">We'd Love to Hear from You</span>
                            </h1>
                            <p className="mt-6 text-lg text-gray-600 max-w-2xl mx-auto lg:mx-0">
                                Whether you have a question about our services, pricing, or anything else, our team is ready to answer all your questions.
                            </p>

                            <div className="mt-8 flex flex-wrap gap-4 justify-center lg:justify-start">
                                <a
                                    href="#contact-form"
                                    className="px-6 py-3 rounded-lg bg-gradient-to-r from-customBlue to-middleBlue text-white font-medium shadow-lg shadow-blue-500/20 hover:shadow-blue-500/30 transition-all duration-300 transform hover:-translate-y-1"
                                >
                                    Contact Us Now
                                </a>
                                <a
                                    href="tel:+918968881110"
                                    className="px-6 py-3 rounded-lg border border-gray-300 text-gray-700 font-medium hover:border-middleBlue hover:text-middleBlue transition-all duration-300 flex items-center"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                    </svg>
                                    Call Us
                                </a>
                            </div>

                            {/* Trust indicators */}
                            <div className="mt-12 flex flex-wrap items-center justify-center lg:justify-start gap-x-8 gap-y-4 text-sm text-gray-500">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-middleBlue mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                    </svg>
                                    Secure & Confidential
                                </div>
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-middleBlue mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    24 Hour Response Time
                                </div>
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-middleBlue mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                                    Expert Team
                                </div>
                            </div>
                        </div>

                        {/* Right side image/illustration */}
                        <div className="flex justify-center">
                            <div className="relative w-full max-w-lg">
                                {/* Decorative elements */}
                                <div className="absolute -top-4 -right-4 w-72 h-72 bg-middleBlue/10 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
                                <div className="absolute -bottom-8 left-20 w-72 h-72 bg-customBlue/20 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
                                <div className="absolute -bottom-8 -right-4 w-72 h-72 bg-doubleBlue/10 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>

                                {/* Main image container */}
                                <div className="relative">
                                    <div className="bg-white rounded-2xl shadow-2xl overflow-hidden p-2">
                                        <img
                                            src="https://ik.imagekit.io/zfayt6inj/contact.jpg?updatedAt=1742662926948"
                                            alt="Contact Us"
                                            className="rounded-xl object-cover w-full h-full"
                                        />

                                        {/* Floating card */}
                                        <div className="absolute -bottom-6 -right-6 bg-white p-4 rounded-lg shadow-lg transform rotate-3 flex items-center space-x-3">
                                            <div className="bg-green-500 h-8 w-8 rounded-full flex items-center justify-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                </svg>
                                            </div>
                                            <div className="text-sm font-medium">We respond quickly!</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact Info Cards */}
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                    {/* Email Card */}
                    <div className="bg-white overflow-hidden rounded-2xl shadow-lg group hover:shadow-xl transform hover:-translate-y-2 transition-all duration-300">
                        <div className="relative p-8 text-center">
                            <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-indigo-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                            <div className="relative">
                                <div className="h-16 w-16 mx-auto bg-gradient-to-r from-customBlue to-middleBlue rounded-full flex items-center justify-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-8 w-8 text-white"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                        />
                                    </svg>
                                </div>
                                <h3 className="mt-6 text-xl font-semibold text-gray-900">
                                    Chat to us
                                </h3>
                                <p className="mt-2 text-gray-500">
                                    Our friendly team is here to help.
                                </p>
                                <p className="mt-3 text-customBlue font-medium">
                                    support@dodunsoftsolutions.com
                                </p>
                                <div className="mt-6">
                                    <a
                                        href="mailto:support@dodunsoftsolutions.com"
                                        className="text-sm font-medium text-middleBlue hover:text-indigo-800 transition-colors duration-300"
                                    >
                                        Send an email →
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Office Card */}
                    <div className="bg-white overflow-hidden rounded-2xl shadow-lg group hover:shadow-xl transform hover:-translate-y-2 transition-all duration-300">
                        <div className="relative p-8 text-center">
                            <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-indigo-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                            <div className="relative">
                                <div className="h-16 w-16 mx-auto bg-gradient-to-r from-doubleBlue to-middleBlue rounded-full flex items-center justify-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-8 w-8 text-white"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                                        />
                                    </svg>
                                </div>
                                <h3 className="mt-6 text-xl font-semibold text-gray-900">
                                    Office
                                </h3>
                                <p className="mt-2 text-gray-500">
                                    Visit us at our headquarters
                                </p>
                                <p className="mt-3 text-gray-600">
                                    GM Plaza, Phase -7 Industrial Area, Sector-73, Mohali, India
                                </p>
                                <div className="mt-6">
                                    <a
                                        href="#map-location"
                                        className="text-sm font-medium text-middleBlue hover:text-indigo-800 transition-colors duration-300"
                                    >
                                        View on map →
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Phone Card */}
                    <div className="bg-white overflow-hidden rounded-2xl shadow-lg group hover:shadow-xl transform hover:-translate-y-2 transition-all duration-300">
                        <div className="relative p-8 text-center">
                            <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-indigo-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                            <div className="relative">
                                <div className="h-16 w-16 mx-auto bg-gradient-to-r from-customBlue to-doubleBlue rounded-full flex items-center justify-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-8 w-8 text-white"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                        />
                                    </svg>
                                </div>
                                <h3 className="mt-6 text-xl font-semibold text-gray-900">
                                    Phone
                                </h3>
                                <p className="mt-2 text-gray-500">
                                    Mon-Sat from 10:30am to 7:30pm
                                </p>
                                <p className="mt-3 text-customBlue font-medium">
                                    +91-8968881110
                                </p>
                                <div className="mt-6">
                                    <a
                                        href="tel:+918968881110"
                                        className="text-sm font-medium text-middleBlue hover:text-indigo-800 transition-colors duration-300"
                                    >
                                        Call us now →
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact Form and Map */}
            <div
                className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8"
                id="contact-form"
            >
                <div className="text-center mb-16">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        <span className="block">Ready to get started?</span>
                        <span className="block mt-2 text-middleBlue">
                            Get in touch with us today.
                        </span>
                    </h2>
                    <p className="mt-4 text-lg text-gray-500 max-w-2xl mx-auto">
                        Fill out the form below and our team will get back to you within 24
                        hours.
                    </p>
                </div>

                <div className="bg-white overflow-hidden shadow-2xl rounded-2xl">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        {/* Contact Form */}
                        <div className="p-8 lg:p-12 bg-white">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                    <div>
                                        <label
                                            htmlFor="firstName"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            First Name
                                        </label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <input
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-3 border"
                                                placeholder="John"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="lastName"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Last Name
                                        </label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <input
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-3 border"
                                                placeholder="Doe"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Email
                                        </label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-3 border"
                                                placeholder="john@example.com"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="phone"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Phone Number
                                        </label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <input
                                                type="tel"
                                                name="phone"
                                                id="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-3 border"
                                                placeholder="+91 98765 43210"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label
                                        htmlFor="message"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Message
                                    </label>
                                    <div className="mt-1">
                                        <textarea
                                            name="message"
                                            id="message"
                                            rows="5"
                                            value={formData.message}
                                            onChange={handleChange}
                                            className="block w-full border border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-3"
                                            placeholder="Tell us about your project or query..."
                                            required
                                        ></textarea>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`w-full flex justify-center items-center py-3 px-6 border border-transparent rounded-lg shadow-sm text-base font-medium text-white ${isSubmitting || isSubmitted
                                                ? "bg-indigo-400"
                                                : "bg-gradient-to-r from-customBlue to-middleBlue hover:from-customBlue hover:to-indigo-700"
                                            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300`}
                                    >
                                        {isSubmitting ? (
                                            <>
                                                <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                                Sending...
                                            </>
                                        ) : isSubmitted ? (
                                            <>
                                                <svg
                                                    className="h-5 w-5 mr-2 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                                Message Sent!
                                            </>
                                        ) : (
                                            "Send Message"
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>

                        {/* Map */}
                        <div
                            className="h-full min-h-[500px] lg:min-h-0 bg-gray-100 relative"
                            id="map-location"
                        >
                            <div className="absolute inset-0 bg-customBlue opacity-10 z-10 pointer-events-none"></div>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.145327051417!2d76.6961861841247!3d30.71431462102898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fefdcb3a9035b%3A0x46edab8a4ca6d8a4!2sDODUN%20SOFT%20SOLUTIONS%20(OPC)%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sus!4v1742657856651!5m2!1sen!2sus"
                                className="w-full h-full border-0"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Dodun Soft Solution Office Location"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
