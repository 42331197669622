import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { Link } from 'react-router-dom';

import { Search, PlaneTakeoff, Palette, Code, CheckCircle2, Rocket, ArrowRight, ChevronRight } from 'lucide-react';

const OurProcess = () => {
  const [activeStepId, setActiveStepId] = useState(1);

  const processSteps = [
    {
      id: 1,
      title: 'Discovery',
      subtitle: 'Understanding Your Vision',
      description: 'We begin by understanding your business, goals, and requirements through detailed consultations and research.',
      icon: <Search />,
      color: 'bg-indigo-100 text-indigo-600',
      details: [
        'Initial consultation with stakeholders',
        'Business goals and requirements analysis',
        'Market research and competitor analysis',
        'User persona development',
        'Solution scope definition'
      ]
    },
    {
      id: 2,
      title: 'Planning',
      subtitle: 'Creating The Roadmap',
      description: 'We develop a comprehensive plan outlining timelines, resources, technologies, and milestones for your project.',
      icon: <PlaneTakeoff />,
      color: 'bg-blue-100 text-blue-600',
      details: [
        'Project roadmap creation',
        'Technology stack selection',
        'Resource allocation',
        'Timeline and milestone planning',
        'Risk assessment and mitigation strategies'
      ]
    },
    {
      id: 3,
      title: 'Design',
      subtitle: 'Crafting Experiences',
      description: 'Our team creates intuitive designs and develops robust, scalable solutions using cutting-edge technologies.',
      icon: <Palette />,
      color: 'bg-emerald-100 text-emerald-600',
      details: [
        'UI/UX wireframing and prototyping',
        'Visual design and branding integration',
        'User flow optimization',
        'Responsive design principles',
        'Accessibility compliance'
      ]
    },
    {
      id: 4,
      title: 'Development',
      subtitle: 'Building Solutions',
      description: 'We transform designs into functional products with clean, efficient code and powerful backend systems.',
      icon: <Code />,
      color: 'bg-amber-100 text-amber-600',
      details: [
        'Frontend implementation',
        'Backend system development',
        'Database integration',
        'API construction',
        'Third-party service integration'
      ]
    },
    {
      id: 5,
      title: 'Testing',
      subtitle: 'Ensuring Quality',
      description: 'Rigorous quality assurance processes ensure your solution functions flawlessly across all platforms and scenarios.',
      icon: <CheckCircle2 />,
      color: 'bg-rose-100 text-rose-600',
      details: [
        'Functional testing across devices',
        'Performance and load testing',
        'Security vulnerability assessment',
        'User acceptance testing',
        'Accessibility compliance verification'
      ]
    },
    {
      id: 6,
      title: 'Launch',
      subtitle: 'Delivering Excellence',
      description: 'We carefully launch your solution, ensuring smooth integration with existing systems and ongoing support.',
      icon: <Rocket />,
      color: 'bg-purple-100 text-purple-600',
      details: [
        'Deployment strategy execution',
        'Infrastructure optimization',
        'User onboarding and training',
        'Post-launch monitoring',
        'Continuous improvement planning'
      ]
    }
  ];

  const activeStep = processSteps.find(step => step.id === activeStepId);

  // Initialize AOS on component mount
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Trigger animation only once
    });
  }, []);

  return (
    <div className="bg-gradient-to-b from-white to-gray-50 py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center max-w-3xl mx-auto mb-16" data-aos="fade-up">
          <div className="inline-block px-4 py-1 rounded-full bg-blue-50 text-extraBlue font-medium text-sm mb-4">
            Our Approach
          </div>
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Development Process</h2>
          <p className="text-lg text-gray-600">
            We follow a structured, transparent approach to ensure successful outcomes for every project.
          </p>
        </div>

        {/* Process Steps */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-12">
          {processSteps.map(step => (
            <button
              key={step.id}
              onClick={() => setActiveStepId(step.id)}
              className={`relative rounded-xl p-6 text-left transition-all duration-200 ${activeStepId === step.id
                  ? 'bg-white shadow-lg border-2 border-blue-100 transform -translate-y-1'
                  : 'bg-white/60 border border-gray-100 hover:bg-white hover:shadow'
                }`}
              data-aos="fade-up"
            >
              <div className={`w-12 h-12 rounded-lg ${step.color} flex items-center justify-center mb-4`}>
                {React.cloneElement(step.icon, { className: "w-6 h-6" })}
              </div>
              <h3 className="font-semibold text-gray-900">{step.title}</h3>
              <div className="text-sm text-gray-500">{step.subtitle}</div>

              {activeStepId === step.id && (
                <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-white border-2 border-indigo-100 rotate-45"></div>
              )}
            </button>
          ))}
        </div>

        {/* Active Step Details */}
        <div className="bg-white rounded-2xl shadow-lg border border-gray-100 overflow-hidden mb-16" data-aos="fade-up">
          <div className="md:flex">
            <div className="md:w-1/3 p-8 md:p-10 bg-gray-50 border-r border-gray-100">
              <div className={`w-16 h-16 rounded-xl ${activeStep.color} flex items-center justify-center mb-6`}>
                {React.cloneElement(activeStep.icon, { className: "w-8 h-8" })}
              </div>
              <h3 className="text-2xl font-bold text-gray-900 mb-2">{activeStep.title}</h3>
              <div className="text-darkBlue font-medium mb-4">{activeStep.subtitle}</div>
              <p className="text-gray-600">{activeStep.description}</p>

              <div className="mt-8 pt-8 border-t border-gray-200">
                <div className="flex items-center justify-between">
                  <div className="text-sm text-gray-500">Step {activeStep.id} of {processSteps.length}</div>
                  <div className="flex space-x-1">
                    {processSteps.map(step => (
                      <button
                        key={step.id}
                        onClick={() => setActiveStepId(step.id)}
                        className={`w-2 h-2 rounded-full ${activeStepId === step.id ? 'bg-darkBlue' : 'bg-gray-300'
                          }`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="md:w-2/3 p-8 md:p-10">
              <h4 className="text-lg font-semibold text-gray-900 mb-6">What happens during this phase:</h4>

              <div className="grid md:grid-cols-2 gap-6 mb-8">
                {activeStep.details.map((detail, index) => (
                  <div key={index} className="flex" data-aos="fade-up">
                    <div className={`w-8 h-8 rounded-full ${activeStep.color} flex-shrink-0 flex items-center justify-center mr-4 mt-0.5`}>
                      {index + 1}
                    </div>
                    <div>
                      <p className="text-gray-700">{detail}</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-8 pt-8 border-t border-gray-100 flex justify-between items-center">
                <div className="text-sm text-gray-500">
                  Want to learn more about our {activeStep.title.toLowerCase()} phase?
                </div>
                <Link to="/contact">
                  <button className="inline-flex items-center px-5 py-2.5 rounded-lg bg-blue-50 text-doubleBlue font-medium hover:bg-blue-100 transition-colors">
                    Reach out to us <ChevronRight className="ml-1 w-4 h-4" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProcess;
